import React, { useEffect, useState, useRef } from "react";
import "./calendarStyles1.css";
import { RxDotsHorizontal } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import expandIcons1 from "../assets/Images/TelehealthDashboard/expandIcons1.png";
import ModalComponent from "./Modal";
import { getBucketURL } from "../utils/regex";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { useTranslation } from 'react-i18next';

const localizer = momentLocalizer(moment);
moment.updateLocale("en", { week: { dow: 1 } });

const NextSchedule = ({ onHideTile2 }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const calendarRef = useRef(null);

  const [selected, setSelected] = useState(new Date());
  const [weekData1, setWeekData1] = useState([]);
  const { dashboard } = useSelector((state) => state.dashboard);
  const [showHideTile, setShowHideTile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState("");

  const formatWeekData1 = (weekPatients) => {
    const now = new Date();
    return Object.entries(weekPatients).flatMap(([date, patients]) => {
      return patients.map((patient) => {
        const startDateTime = moment(patient.Session_Info.Start_time).toDate();
        const endDateTime = moment(patient.Session_Info.End_time).toDate();

        return {
          title: patient.Patient_Info.Name,
          start: startDateTime,
          end: endDateTime,
          allDay: false,
          resource: patient,
          isUpcoming: endDateTime > now,
        };
      });
    });
  };

  const EventComponent = ({ event }) => {
    const titleText = event.title;
    const resourceText = `${event.resource.Patient_Info.Gender} - ${event.resource.Patient_Info.Age} ${t('years')}`;
    const combinedText = `${titleText}, ${resourceText}`;

    const eventClass = event.isUpcoming
      ? "rbc-upcoming-event"
      : "rbc-past-event";

    return (
      <div className={`flex flex-col justify-center items-start ${eventClass}`}>
        <h1 className="text-[#545454] text-[12px] font-regular font-poppins text-left truncate flex items-center">
          <span className="text-[#545454] mr-1">•</span>
          {combinedText.length > 22
            ? `${combinedText.slice(0, 22)}...`
            : combinedText}
        </h1>
      </div>
    );
  };

  const CustomTimeSlotWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
      className: "custom-time-slot",
    });

  const handleEventSelect = async (event) => {
    setSelectedEvent(event);
    setShowModal(true);

    try {
      const profileUrl = await getBucketURL(
        event.resource.Patient_Info.ProfileImageUrl
      );
      setProfileImageUrl(profileUrl);
    } catch (error) {
      console.log(t('fetchProfilePicError'), error);
    }
  };

  const handleNavigate = (date) => {
    setSelected(date);
  };

  const getWeekDates = (date) => {
    const start = moment(date).startOf("week");
    const end = moment(date).endOf("week");
    return { start, end };
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.onNavigate("PREV");
    };

    const goToNext = () => {
      toolbar.onNavigate("NEXT");
    };

    const goToBackMonth = () => {
      const newDate = moment(toolbar.date).subtract(1, "month").toDate();
      toolbar.onNavigate("DATE", newDate);
    };

    const goToNextMonth = () => {
      const newDate = moment(toolbar.date).add(1, "month").toDate();
      toolbar.onNavigate("DATE", newDate);
    };

    const label = () => {
      const { start, end } = getWeekDates(toolbar.date);
      return (
        <span className="custom-toolbar-label">
          {start.format("DD MMM")} - {end.format("DD MMM")}
        </span>
      );
    };

    return (
      <div className="custom-toolbar bg-[#FFFFFF] rounded-lg py-2 flex flex-row justify-start items-center relative px-5">
        {showHideTile ? (
          <div
            onClick={() => {
              setShowHideTile(false);
              onHideTile2("showNextSchedule");
            }}
            className={`cursor-pointer flex justify-center items-center py-1 px-2 bg-[#E5F2FF] rounded-3xl`}
          >
            <h1
              className={`text-[#3CA5DC] text-[11.03px] font-semibold font-poppins`}
            >
              {t('hideTile')}
            </h1>
          </div>
        ) : (
          <div
            onClick={() => setShowHideTile(true)}
            className="cursor-pointer flex justify-center items-center bg-[#E5F2FF] rounded-full p-1 w-[30px] h-[30px]"
          >
            <RxDotsHorizontal className="text-[#3CA5DC] text-lg" />
          </div>
        )}
        <h1 className="flex text-[#1C1C1C] text-sm ml-3 font-semibold font-poppins">
          {t('yourWeeklyCalendar')}
        </h1>

        <div className="ml-auto flex justify-center items-center border border-[#D4D4D4] rounded-lg py-1">
          <div className="flex items-center">
            <button
              className="bg-white border border-gray-300 text-gray-600 font-semibold py-1 px-2 rounded-l"
              onClick={goToBack}
            >
              <IoIosArrowBack />
            </button>
            <span className="custom-toolbar-label font-poppins font-normal text-black text-xs">
              {label()}
            </span>
            <button
              className="bg-white border border-gray-300 text-gray-600 font-semibold py-1 px-2 rounded-r"
              onClick={goToNext}
            >
              <IoIosArrowForward />
            </button>
          </div>
        </div>

        <div className="flex justify-center items-center border border-[#D4D4D4] rounded-lg py-1 ml-2">
          <div className="flex items-center">
            <button
              className="bg-white border border-gray-300 text-gray-600 font-semibold py-1 px-2 rounded-l"
              onClick={goToBackMonth}
            >
              <IoIosArrowBack />
            </button>
            <span className="custom-toolbar-label font-poppins font-normal text-black text-xs">
              {moment(toolbar.date).format("MMMM YYYY")}
            </span>
            <button
              className="bg-white border border-gray-300 text-gray-600 font-semibold py-1 px-2 rounded-r"
              onClick={goToNextMonth}
            >
              <IoIosArrowForward />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const CustomDayHeader = ({ label, date }) => {
    const isToday = moment().isSame(date, "day");
    const dayName = moment(date).format("ddd");
    const formattedDate = isToday ? t('today') : moment(date).format("DD");

    return (
      <div>
        <div
          style={{
            color: "#545454",
            fontSize: "10.40px",
            fontFamily: "Poppins",
            fontWeight: "400",
          }}
        >
          {dayName}
        </div>
        <div
          style={{
            fontWeight: "600",
            color: "#1C1C1C",
            fontSize: "12px",
            fontFamily: "Poppins",
          }}
        >
          {formattedDate}
        </div>
      </div>
    );
  };

  // Add useEffect to dynamically update styles
  useEffect(() => {
    const updateEventClasses = () => {
      const events = calendarRef?.current?.querySelectorAll(".rbc-event");

      events?.forEach((event) => {
        const eventContent = event?.querySelector(".rbc-event-content");
        if (eventContent) {
          // Check for rbc-upcoming-event or rbc-past-event in the eventContent
          const upcomingEvent = eventContent?.querySelector(
            ".rbc-upcoming-event"
          );
          const pastEvent = eventContent?.querySelector(".rbc-past-event");

          if (upcomingEvent) {
            // Add upcoming-event class to rbc-event and remove from child
            event?.classList?.add("rbc-upcoming-events");
            event?.classList?.remove("rbc-past-events");
            upcomingEvent?.classList?.remove("rbc-upcoming-event");
          } else if (pastEvent) {
            // Add past-event class to rbc-event and remove from child
            event?.classList?.add("rbc-past-events");
            event?.classList?.remove("rbc-upcoming-events");
            pastEvent?.classList?.remove("rbc-past-event");
          }
        }
      });
    };
    updateEventClasses(); // Initial update
    // Cleanup function to remove classes if the component is unmounted or updated
    return () => {
      const events = calendarRef?.current?.querySelectorAll(".rbc-event");
      events?.forEach((event) => {
        event?.classList?.remove("rbc-upcoming-events", "rbc-past-events");
      });
    };
  }, [weekData1]);

  useEffect(() => {
    const updateTimeContentClasses = () => {
      if (calendarRef.current) {
        const timeContentElements =
          calendarRef.current.querySelectorAll(".rbc-time-content");
        timeContentElements.forEach((element) => {
          element.classList.add("custom-time-content");
        });
      }
    };

    updateTimeContentClasses();

    // Optional: Cleanup function if needed
    return () => {
      if (calendarRef.current) {
        const timeContentElements =
          calendarRef.current.querySelectorAll(".rbc-time-content");
        timeContentElements.forEach((element) => {
          element.classList.remove("custom-time-content");
        });
      }
    };
  }, []);

  useEffect(() => {
    const weekDataFormatted = formatWeekData1(dashboard);
    setWeekData1(weekDataFormatted);
  }, [dashboard]);

  return (
    <div
      className={`overflow-y-hidden h-[47vh] flex-1 basis-[60%] rounded-lg relative`}
    >
      <div className={`flex flex-col justify-center item-center`}>
        <div className="mt-[0px]">
          <div className="overflow-x-hidden custom-calendar" ref={calendarRef}>
            <Calendar
              localizer={localizer}
              scrollToTime={moment()
                .set({ h: moment().hours(), m: moment().minutes() - 30 })
                .toDate()}
              events={weekData1}
              startAccessor="start"
              endAccessor="end"
              views={["week"]}
              defaultView="week"
              date={selected}
              components={{
                event: EventComponent,
                timeSlotWrapper: CustomTimeSlotWrapper,
                toolbar: CustomToolbar,
                week: {
                  header: CustomDayHeader,
                },
              }}
              onSelectEvent={handleEventSelect}
              onNavigate={handleNavigate}
              formats={{
                dayFormat: (date, culture, localizer) =>
                  `${localizer.format(
                    date,
                    "ddd",
                    culture
                  )}\n${localizer.format(date, "DD MMM", culture)}`,
              }}
              showMultiDayTimes
              step={60}
              timeslots={1}
              // min={
              //   new Date(
              //     new Date().getFullYear(),
              //     new Date().getMonth(),
              //     new Date().getDate(),
              //     9,
              //     0
              //   )
              // }
              // max={
              //   new Date(
              //     new Date().getFullYear(),
              //     new Date().getMonth(),
              //     new Date().getDate(),
              //     23,
              //     30
              //   )
              // }
            />
          </div>

          <div className="flex flex-row justify-end items-center w-full bg-[#FFFFFF] rounded-lg shadow px-4 pb-2 pt-1 absolute bottom-0 right-0">
            <button
              className="text-sky-400 text-2xl active:scale-90 transition-transform border rounded-lg border-[#D1D1D1] w-7 h-7 p-1"
              onClick={() => {
                // navigate("/clinicianDashboard/nextSchedule");
              }}
            >
              <img
                src={expandIcons1}
                className="w-full h-full object-contain"
                alt={t('expandIcons')}
              />
            </button>
          </div>
        </div>
      </div>

      {showModal && selectedEvent && (
        <ModalComponent
          show={showModal}
          handleClose={() => setShowModal(false)}
          outSideModalPress={false}
        >
          <div className="w-[490px] p-2">
            {/* Event HEADER */}
            <div className="flex flex-col w-full justify-start items-start">
              <h1 className="text-[#1D2939] text-2xl font-semibold font-poppins">
                {t('patientInfo')}
              </h1>
            </div>
            <div className="w-full bg-[#EAECF0] h-[1px] my-3"></div>

            {/* Event CONTENT */}
            <div className="flex flex-col w-full justify-start items-start">
              <button
                onClick={() => {
                  navigate("/patientDashboard/patientProfile", {
                    state: {
                      ...selectedEvent.resource.Patient_Info,
                      Image: profileImageUrl,
                    },
                  });
                }}
                className="flex flex-row justify-start items-center gap-2 w-full mb-2"
              >
                <div className="relative flex justify-center items-center w-[43px] h-[43px] rounded-full group">
                  <img
                    src={profileImageUrl}
                    className="w-[40px] h-[40px] object-contain rounded-full"
                    alt={t('userImage')}
                  />
                  <div className="absolute inset-0 rounded-full border-4 border-transparent group-hover:border-[#E5F2FF] group-hover:shadow-xl group-hover:shadow-[#E5F2FF]/50 transition-all duration-300"></div>
                </div>

                <div className="flex flex-col justify-center items-start">
                  <h1 className="text-[#3CA5DC] text-sm font-semibold font-poppins underline">
                    {selectedEvent?.resource?.Patient_Info?.Name}
                  </h1>
                </div>
              </button>
              {/* Patient Info Details */}
              <div className="flex flex-col w-full shadow-md rounded-lg">
                <div className="flex justify-between items-center w-full rounded-t-lg border border-[#F2F4F7] px-6 py-4">
                  <span className="text-[#1D2939] text-sm font-normal font-poppins">
                    {t('genderAge')}
                  </span>
                  <span className="text-[#1D2939] text-sm font-normal font-poppins">{`${selectedEvent?.resource?.Patient_Info?.Gender}, ${selectedEvent?.resource?.Patient_Info?.Age}`}</span>
                </div>
                <div className="flex justify-between items-center w-full border border-[#F2F4F7] px-6 py-2">
                  <span className="text-[#1D2939] text-sm font-normal font-poppins">
                    {t('contact')}
                  </span>
                  <span className="text-[#1D2939] text-sm font-normal font-poppins text-right">
                    {selectedEvent?.resource?.Patient_Info?.Phone}
                    <br />
                    {selectedEvent?.resource?.Patient_Info?.Email}
                  </span>
                </div>
                <div className="flex justify-between items-center w-full rounded-b-lg border border-[#F2F4F7] px-6 py-4">
                  <span className="text-[#1D2939] text-sm font-normal font-poppins">
                    {t('assignedDoctor')}
                  </span>
                  <span className="text-[#1D2939] text-sm font-normal font-poppins ">
                    {t('doctorName', { name: selectedEvent?.resource?.Doctor_Info?.Name })}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </ModalComponent>
      )}
    </div>
  );
};

export default NextSchedule;