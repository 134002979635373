import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import tile1 from "../assets/Images/MasterDataManagement/tile1.png";
import tile2 from "../assets/Images/MasterDataManagement/tile2.png";
import tile3 from "../assets/Images/MasterDataManagement/tile3.png";
import tile4 from "../assets/Images/MasterDataManagement/tile4.svg";
import tile5 from "../assets/Images/MasterDataManagement/tile5.png";
import tile6 from "../assets/Images/MasterDataManagement/tile6.png";
import tile7 from "../assets/Images/MasterDataManagement/tile7.png";
import tile8 from "../assets/Images/MasterDataManagement/tile8.png";
import tile9 from "../assets/Images/MasterDataManagement/tile9.png";
import tile10 from "../assets/Images/MasterDataManagement/tile10.png";
import tile11 from "../assets/Images/MasterDataManagement/tile11.png";
import tile12 from "../assets/Images/MasterDataManagement/tile12.png";
import tile13 from "../assets/Images/MasterDataManagement/tile13.png";
import tile14 from "../assets/Images/MasterDataManagement/tile14.png";
import tile15 from "../assets/Images/MasterDataManagement/tile15.png";
import Breadcrumb from "../components/Breadcrumb";
import { useTranslation } from "react-i18next";

function ServiceManagement() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      {/* HEADER */}
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        {t("serviceManagement")}
      </div>
      {/* <Breadcrumb /> */}

      {/* Content */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-5">
        {TILES.map((tile) => (
          <div
            key={tile.id}
            className="relative h-40 bg-sky-100 p-6 rounded-2xl shadow-lg cursor-pointer hover:scale-105 duration-200 transition-all active:scale-100"
            onClick={() => navigate(tile.route)}
          >
            <div className="text-[#011370] text-base font-bold font-poppins relative z-10">
              {t(tile.title)}
            </div>
            <ul className="list-disc mt-5 relative z-10">
              {tile.moreInfo.map((info, index) => (
                <li
                  key={index}
                  className="text-black text-xs font-normal font-poppins w-[80%]"
                >
                  {t(info)}
                </li>
              ))}
            </ul>
            <img
              src={tile.image}
              alt=""
              className="h-24 object-contain absolute bottom-5 right-4"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServiceManagement;

const TILES = [
  {
    id: 1,
    image: tile1,
    route: "branchManagement",
    title: "maintainAddress",
    moreInfo: ["manageInAppNotifications", "webPortalNotifications"],
  },
  {
    id: 2,
    image: tile2,
    route: "telehealthData",
    title: "teleHealthData",
    moreInfo: ["manageInAppNotifications", "webPortalNotifications"],
  },
  {
    id: 3,
    image: tile3,
    route: "doctorManagement",
    title: "maintainDoctors",
    moreInfo: ["manageInAppNotifications", "webPortalNotifications"],
  },
  {
    id: 4,
    image: tile4,
    route: "nurseMangement",
    title: "maintainNurses",
    moreInfo: ["manageInAppNotifications", "webPortalNotifications"],
  },
  {
    id: 5,
    image: tile5,
    route: "labPartners",
    title: "maintainLabPartners",
    moreInfo: ["manageInAppNotifications", "webPortalNotifications"],
  },
  {
    id: 6,
    image: tile6,
    route: "/comingSoon",
    title: "maintainPharmacy",
    moreInfo: ["manageInAppNotifications", "webPortalNotifications"],
  },
  {
    id: 7,
    image: tile7,
    route: "servicesAndRateCard",
    title: "serviceRateCard",
    moreInfo: ["manageInAppNotifications", "webPortalNotifications"],
  },
  {
    id: 8,
    image: tile8,
    route: "accessManagement",
    title: "accessManagement",
    moreInfo: ["manageInAppNotifications", "webPortalNotifications"],
  },
  {
    id: 9,
    image: tile9,
    route: "treatmentProgram",
    title: "patientTreatmentProgram",
    moreInfo: ["manageInAppNotifications", "webPortalNotifications"],
  },
  {
    id: 10,
    image: tile10,
    route: "clinicCapacity",
    title: "clinicCapacityManager",
    moreInfo: ["manageInAppNotifications", "webPortalNotifications"],
  },
  {
    id: 11,
    image: tile11,
    route: "ourServices",
    title: "inAppServices",
    moreInfo: ["addOrModifyServices"],
  },
  {
    id: 12,
    image: tile12,
    route: "/comingSoon",
    title: "licensingSubscription",
    moreInfo: ["manageInAppNotifications", "webPortalNotifications"],
  },
  {
    id: 13,
    image: tile13,
    route: "notificationManagement",
    title: "notificationManagement",
    moreInfo: ["manageInAppNotifications", "webPortalNotifications"],
  },
  {
    id: 14,
    image: tile14,
    route: "/comingSoon",
    title: "paymentsBillings",
    moreInfo: ["manageInAppNotifications", "webPortalNotifications"],
  },
  {
    id: 15,
    image: tile15,
    route: "/settings",
    title: "settings",
    moreInfo: ["manageInAppNotifications", "webPortalNotifications"],
  },
  {
    id: 16,
    image: tile15,
    route: "consultationForm",
    title: "consultationForm",
    moreInfo: ["manageInAppNotifications", "webPortalNotifications"],
  },
];
