import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  initiatePayment,
  upgradeSubscription,
} from "../services/operations/membershipAPI";

function PaymentProcessing() {
  const navigate = useNavigate();
  const location = useLocation();
  const { plan, isUpgrading } = location.state || {};
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);

  const handleRequestStripePayment = async () => {
    try {
      const body = {
        CPSID: plan?.CPSID,
        ORGID: user?.ORGID,
        email: user?.Email,
        // success_url: "http://localhost:3000/payment-success",
        // cancel_url: "http://localhost:3000/payment-failed",
        success_url: "https://devdoctor.alleshealth.com/payment-success",
        cancel_url: "https://devdoctor.alleshealth.com/payment-failed",
      };
      let res;
      if (isUpgrading) {
        res = await upgradeSubscription(token, body);
      } else {
        res = await initiatePayment(token, body);
      }
      localStorage.setItem(
        "stripeResponse",
        JSON.stringify(res?.data?.sessionId)
      );
      localStorage.setItem("isUpgrading", isUpgrading);
      window.location.href = res?.data?.sessionUrl;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleRequestStripePayment();
  }, [token]);

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Payment Processing</h2>
      <div className="loader"></div> {/* Apply the loader class */}
      <p style={styles.text}>Your payment is being processed...</p>
      <p style={styles.note}>
        To ensure a successful transaction, please refrain from closing or
        refreshing this page.
      </p>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  heading: {
    fontSize: "24px",
    marginBottom: "20px",
  },
  text: {
    fontSize: "16px",
  },
  note: {
    fontSize: "14px",
    marginTop: "20px",
    fontStyle: "italic",
    color: "red",
  },
};

export default PaymentProcessing;
