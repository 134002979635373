import EN from "../assets/Images/flags/EN.png";
import DE from "../assets/Images/flags/DE.png";
import FR from "../assets/Images/flags/FR.png";
import IT from "../assets/Images/flags/IT.png";

export const localizationLanguageList = [
  {
    id: 1,
    name: "English",
    code: "en",
    icon: EN,
  },
  {
    id: 2,
    name: "German",
    code: "de",
    icon: DE,
  },
  {
    id: 3,
    name: "French",
    code: "fr",
    icon: FR,
  },
  {
    id: 4,
    name: "Italian",
    code: "it",
    icon: IT,
  },
];