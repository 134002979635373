import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../components/Dropdown";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import {
  bookAppointment,
  getAvailableSlotsForDoctorOnSpecifiedDate,
  getSlotAvailabilityStatus,
} from "../services/operations/dashboardAPI";
import moment from "moment";
import TimeSlotBgImage from "../assets/Images/BookAppointment/TimeSlotBgImage.png";
import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import "./bookAppointment.css";

function BookAppointment() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const patient = location.state || {};
  const { allDoctors } = useSelector((state) => state.masterData);
  const { token } = useSelector((state) => state.auth);
  const [selected, setSelected] = useState(moment().format("YYYY-MM-DD"));
  const [slots, setSlots] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [formData, setFormData] = useState({
    AppointmentType: "",
    Doctor: "",
  });
  const [errors, setErrors] = useState({
    AppointmentType: "",
    Doctor: "",
  });

  const AppointmentOptions = [
    { value: "TeleHealth", label: t('teleHealth') },
    { value: "In-Clinic", label: t('inClinic') },
  ];

  const formatSelectedSlot = (selectedSlot) => {
    const [startTime, endTime] = selectedSlot.split(" - ");

    // Format the times to "hh:mm AM/PM"
    const formattedStartTime = moment(startTime, "hh:mm:ss A").format(
      "hh:mm A"
    );
    const formattedEndTime = moment(endTime, "hh:mm:ss A").format("hh:mm A");

    return `${formattedStartTime} - ${formattedEndTime}`;
  };

  function handleSelect(name, option) {
    setFormData((prev) => ({
      ...prev,
      [name]: option,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  const submitHandler = async (event) => {
    // event.preventDefault();
    const mandatoryFields = ["AppointmentType"];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = t('fieldRequired');
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = t('fieldCannotBeEmpty');
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error(t('fillRequiredFields'));
      setErrors(newErrors);
      console.log("ERRORS", errors);
      return;
    }
    if (!selectedSlot) {
      toast.error(t('selectTimeSlot'));
      return;
    }

    // Extract start and end times from the selected slot
    const [startTime, endTime] = selectedSlot.split(" - ");

    // Convert start and end times to UTC format
    const startDateTime = moment(
      `${selected} ${startTime}`,
      "YYYY-MM-DD hh:mm:ss A"
    )
      .utc()
      .format();
    const endDateTime = moment(
      `${selected} ${endTime}`,
      "YYYY-MM-DD hh:mm:ss A"
    )
      .utc()
      .format();

    let data = {
      Start_time: startDateTime,
      End_time: endDateTime,
      AHUID: patient?.AHUID,
      DID: formData?.Doctor,
      AppointmentType: formData.AppointmentType,
    };

    dispatch(bookAppointment(token, data, navigate));
  };

  useEffect(() => {
    // Calling function to fetch the details
    if (formData.Doctor) {
      (async () => {
        try {
          const res = await getAvailableSlotsForDoctorOnSpecifiedDate(
            token,
            formData.Doctor,
            moment(selected).format("YYYY-MM-DD")
          );
          if (res.status) {
            let availableTimes = res.AvailableTimes;

            // Check if the selected date is today
            if (moment(selected).isSame(moment(), "day")) {
              const currentTime = moment().format("HH:mm:ss A");
              availableTimes = availableTimes.filter((timeSlot) => {
                const [startTime] = timeSlot.split(" - ");
                return moment(startTime, "hh:mm:ss A").isAfter(
                  moment(currentTime, "HH:mm:ss A")
                );
              });
            }
            setSlots(availableTimes);
          } else {
            setSlots([]);
          }
        } catch (error) {
          console.log(t('fetchSlotsError'), error);
        }
      })();
    }
  }, [formData.Doctor, selected]);

  useEffect(() => {
    // Calling function to fetch the details
    if (formData.Doctor) {
      (async () => {
        try {
          const res = await getSlotAvailabilityStatus(
            token,
            formData.Doctor,
            moment().format()
          );

          const available = [];
          const unavailable = [];

          Object.entries(res).forEach(([date, status]) => {
            const dateObj = new Date(date);
            if (status) {
              available.push(dateObj);
            } else {
              unavailable.push(dateObj);
            }
          });
          setAvailableDates(available);
          setUnavailableDates(unavailable);
        } catch (error) {
          console.log(t('fetchSlotsStatusError'), error);
        }
      })();
    }
  }, [formData.Doctor]);

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        {t('bookAppointment')}
      </div>
      <Breadcrumb />

      <div className="flex gap-8 p-8">
        <div className="w-1/3">
          <div className="w-60 bg-white rounded-lg shadow py-7 flex flex-col items-center justify-center">
            <div
              className={`${
                patient?.IsMedicalConciergeMember &&
                " bg-gradient-to-r from-[#F6C86E] to-[#FFEE54] rounded-full p-0.5"
              }`}
            >
              <img
                src={patient.Image}
                alt={t('patientPic')}
                className="w-20 h-20 rounded-full"
              />
            </div>
            {patient?.IsMedicalConciergeMember === 1 && (
              <div className="w-full py-1 bg-yellow-50 flex items-center justify-center mt-4">
                <div className="w-48 text-center text-yellow-600 text-sm font-semibold font-poppins leading-tight">
                  {t('conciergeMemberSince')}{" "}
                  {moment(patient?.MedicalConciergeJoiningDate).format(
                    "DD MMMM YYYY"
                  )}
                </div>
              </div>
            )}
            <div className="text-center text-black text-base font-semibold font-poppins mt-4">
              {patient.Name || patient.FirstName + " " + patient.LastName}
            </div>
            <div className="text-center text-zinc-500 text-xs font-normal font-poppins my-2">
              {t('no')}: {patient.AHUID}
            </div>

            {patient.Age !== null && patient.Age !== undefined ? (
              <div className="text-center text-zinc-900 text-xs font-normal font-poppins">
                {patient.Age} {t('yearsOld')}
              </div>
            ) : (
              <div className="text-center text-zinc-900 text-xs font-normal font-poppins">
                {new Date().getFullYear() - new Date(patient.DOB).getFullYear()}{" "}
                {t('yearsOld')}
              </div>
            )}
          </div>

          {/* Appointment Type */}
          <div className="mt-6">
            <label>
              <p className="text-zinc-900 text-base font-medium font-poppins mb-2">
                {t('appointmentType')}
                <sup className="text-red-600">*</sup>
              </p>
              <Dropdown
                placeholder={t('selectAppointmentType')}
                error={errors.AppointmentType}
                name="AppointmentType"
                options={AppointmentOptions}
                onSelect={handleSelect}
                value={formData.AppointmentType}
                labelKey={"label"}
                valueKey={"value"}
              />
            </label>
          </div>
          {/* Doctor */}
          <div className="mt-6">
            <label>
              <p className="text-zinc-900 text-base font-medium font-poppins mb-2">
                {t('selectDoctor')}
                <sup className="text-red-600">*</sup>
              </p>
              <Dropdown
                placeholder={t('selectDoctor')}
                error={errors.Doctor}
                name="Doctor"
                options={allDoctors}
                onSelect={handleSelect}
                value={formData.Doctor}
                labelKey={"Name"}
                valueKey={"DID"}
              />
            </label>
          </div>
        </div>
        <div className="w-2/3 bg-white rounded-lg shadow p-7">
          <div className="text-zinc-900 text-2xl font-medium font-poppins">
            {t('schedulingCalendar')}
          </div>
          <div className="h-px bg-gray-200 my-4" />
          <div className="flex space-x-10">
            <div>
              <div className="text-zinc-900 text-base font-medium font-poppins mb-2">
                {t('selectDate')}
                <sup className="text-red-600">*</sup>
              </div>
              <DayPicker
                mode="single"
                name="selected"
                style={{
                  "--rdp-accent-color": "#3CA5DC",
                  "--rdp-cell-size": "30px",
                  backgroundColor: "#E5F2FF",
                }}
                className="p-2 shadow rounded-lg"
                selected={selected}
                onSelect={(date) => {
                  setSelected(moment(date).format("YYYY-MM-DD"));
                  setSelectedSlot(null);
                }}
                modifiers={{
                  booked: availableDates,
                  cannotBeBooked: unavailableDates,
                  disabled: { before: moment() },
                }}
                modifiersClassNames={{
                  booked: "booked_day",
                  cannotBeBooked: "cannot_be_booked_day",
                }}
              />
            </div>
            <div className="w-full">
              {slots.length > 0 ? (
                <>
                  <div className="text-zinc-900 text-base font-medium font-poppins mb-2">
                    {t('availableSlots')}
                    <sup className="text-red-600">*</sup>
                  </div>
                  <div className="grid grid-cols-3 gap-5 max-h-[40vh] p-2 overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                    {slots.map((slot, index) => {
                      const [startTime] = slot.split(" - ");
                      const formattedTime = moment(
                        startTime,
                        "hh:mm:ss A"
                      ).format("hh:mm A");
                      return (
                        <button
                          key={index}
                          className={`p-3 rounded-2xl text-sm uppercase leading-3 tracking-wider font-semibold font-poppins
                            ${
                              selectedSlot === slot
                                ? "bg-sky-400 text-white"
                                : "bg-sky-100 text-sky-400"
                            }`}
                          onClick={() => {
                            setSelectedSlot(slot);
                          }}
                        >
                          {formattedTime}
                        </button>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div className="flex items-center justify-center h-full">
                  <img
                    src={TimeSlotBgImage}
                    alt={t('selectDateToShowTimeSlots')}
                    className="w-96 object-contain"
                  />
                </div>
              )}
              {/* Confirmation */}
              {selectedSlot && (
                <>
                  <div className="w-full mt-4 p-4 bg-gray-100 flex flex-col justify-center items-start rounded-lg">
                    <p className="text-zinc-900 text-base font-semibold font-poppins leading-3 tracking-wide">
                      {moment(selected).format("dddd, D MMMM YYYY")}
                    </p>
                    <p className="text-base font-semibold font-poppins text-zinc-400 tracking-wide mt-2">
                      {formatSelectedSlot(selectedSlot)}
                    </p>
                  </div>

                  {/* Button */}
                  <button
                    onClick={submitHandler}
                    className="w-full mt-4 py-3 bg-sky-400 rounded-lg active:scale-95 transition-transform"
                  >
                    <div className="text-white text-base font-semibold font-poppins leading-6 text-center">
                      {t('confirmBooking')}
                    </div>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookAppointment;