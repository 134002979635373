import React, { useState } from "react";
import TitleWithAddButton from "./TitleWithAddButton";
import { CiSearch } from "react-icons/ci";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addPreMedicalCondition } from "../services/operations/patientManagementAPI";
import { addNewPreMedicalCondition } from "../services/operations/masterDataAPI";
import { useTranslation } from "react-i18next";

function MedicalCondition({
  hasEditAccess,
  allPreMedicalConditions,
  medicalCondition,
  patient,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [showAddCard, setShowAddCard] = useState(false);
  const [formData, setFormData] = useState({ PMCName: "" });
  const [errors, setErrors] = useState({ PMCName: "" });
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const handleInputChange2 = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (!value) {
      setSuggestionList([]);
      setShowSuggestions(false);
    } else {
      const filteredSuggestions = allPreMedicalConditions.filter((condition) =>
        condition.Name.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestionList(filteredSuggestions);
      setShowSuggestions(true);
    }
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleAddCondition = (condition) => {
    const conditionExists = medicalCondition.find(
      (c) => c.PMCID === condition.PMCID
    );
    if (conditionExists) {
      toast.error(t("conditionAlreadyExists"));
      return;
    }

    const body = {
      AHUID: patient?.AHUID,
      PMCID: [condition?.PMCID],
    };
    setInputValue("");
    setSuggestionList([]);
    setShowSuggestions(false);
    dispatch(addPreMedicalCondition(token, body, patient));
  };

  const handleAddNewPreMedicalCondition = () => {
    if (!formData.PMCName) {
      setErrors((prev) => ({
        ...prev,
        PMCName: t("nameOfConditionRequired"),
      }));
      toast.error(t("nameOfConditionRequired"));
      return;
    }
    dispatch(addNewPreMedicalCondition(token, formData, patient));
    setFormData({ PMCName: "" });
    setShowAddCard(false);
  };

  const handleCloseAddCard = () => {
    setShowAddCard(false);
    setFormData({ PMCName: "" });
    setErrors({ PMCName: "" });
  };

  if (medicalCondition?.length === 0) {
    return (
      <div>
        {hasEditAccess ? (
          <TitleWithAddButton
            title={t("preMedicalCondition")}
            showAddButton={!showAddCard}
            onPress={() => setShowAddCard(true)}
          />
        ) : (
          <TitleWithAddButton
            title={t("preMedicalCondition")}
            showAddButton={false}
          />
        )}
        {showAddCard ? (
          <div>
            <div className="my-3">
              <label>
                <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                  {t("nameOfCondition")}
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="PMCName"
                  maxLength={20}
                  value={formData.PMCName}
                  onChange={handleOnChange}
                  placeholder={t("enterName")}
                  className={`w-full text-black text-sm font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.PMCName ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            <div className="flex flex-row items-center justify-center mt-4 space-x-4">
              <button
                onClick={handleCloseAddCard}
                className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
              >
                <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
                  {t("cancel")}
                </div>
              </button>
              <button
                onClick={handleAddNewPreMedicalCondition}
                className="w-48 h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-500 active:scale-95"
              >
                <div className="text-white text-base font-semibold font-poppins leading-normal">
                  {t("add")}
                </div>
              </button>
            </div>
          </div>
        ) : (
          <>
            {hasEditAccess && (
              <div className="relative my-4">
                <label className="w-full mx-auto flex flex-row items-center justify-start bg-zinc-100  rounded-2xl py-2 px-4 gap-2">
                  <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
                  <input
                    type="text"
                    placeholder={t("addHere")}
                    value={inputValue}
                    onChange={handleInputChange2}
                    className={`w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent`}
                  />
                </label>
                {showSuggestions && (
                  <div className="absolute left-0 mt-1 w-1/2 bg-white border rounded-lg py-2 space-y-1 shadow-lg z-10 max-h-[35vh] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                    {suggestionList?.length > 0 ? (
                      suggestionList?.slice(0, 4).map((condition) => (
                        <button
                          key={condition.PMCID}
                          onClick={() => handleAddCondition(condition)}
                          className={`w-full px-4 py-2 hover:bg-gray-100`}
                        >
                          <p className="text-left text-zinc-900 text-base font-normal font-poppins leading-normal">
                            {condition.Name}
                          </p>
                        </button>
                      ))
                    ) : (
                      <div className="p-2 flex flex-col space-y-4">
                        <div className="text-center text-black text-sm font-medium font-poppins leading-normal">
                          {t("noMatchesFound")}
                        </div>
                        <button
                          onClick={() => {
                            setInputValue("");
                            setSuggestionList([]);
                            setShowSuggestions(false);
                            setFormData({ PMCName: inputValue });
                            setShowAddCard(true);
                          }}
                          className="text-sky-700 text-base font-semibold font-poppins leading-tight"
                        >
                          {t("addNew")}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="text-neutral-400 text-2xl text-center my-10 font-semibold font-poppins">
              {t("noData")}
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      {hasEditAccess ? (
        <TitleWithAddButton
          title={t("preMedicalCondition")}
          showAddButton={!showAddCard}
          onPress={() => setShowAddCard(true)}
        />
      ) : (
        <TitleWithAddButton
          title={t("preMedicalCondition")}
          showAddButton={false}
        />
      )}
      {showAddCard ? (
        <div>
          <div className="my-3">
            <label>
              <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                {t("nameOfCondition")}
                <sup className="text-red-600">*</sup>
              </p>
              <input
                type="text"
                name="PMCName"
                maxLength={20}
                value={formData.PMCName}
                onChange={handleOnChange}
                placeholder={t("enterName")}
                className={`w-full text-black text-sm font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                  errors.PMCName ? "border-red-500" : ""
                }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>
          <div className="flex flex-row items-center justify-center mt-4 space-x-4">
            <button
              onClick={handleCloseAddCard}
              className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
            >
              <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
                {t("cancel")}
              </div>
            </button>
            <button
              onClick={handleAddNewPreMedicalCondition}
              className="w-48 h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-500 active:scale-95"
            >
              <div className="text-white text-base font-semibold font-poppins leading-normal">
                {t("add")}
              </div>
            </button>
          </div>
        </div>
      ) : (
        <>
          {hasEditAccess && (
            <div className="relative mt-4">
              <label className="w-full mx-auto flex flex-row items-center justify-start bg-zinc-100  rounded-2xl py-2 px-4 gap-2">
                <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
                <input
                  type="text"
                  placeholder={t("addHere")}
                  value={inputValue}
                  onChange={handleInputChange2}
                  className={`w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent`}
                />
              </label>
              {showSuggestions && (
                <div className="absolute left-0 mt-1 w-1/2 bg-white border rounded-lg py-2 space-y-1 shadow-lg z-10 max-h-[35vh] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                  {suggestionList?.length > 0 ? (
                    suggestionList?.slice(0, 4).map((condition) => (
                      <button
                        key={condition.PMCID}
                        onClick={() => handleAddCondition(condition)}
                        className={`w-full px-4 py-2 hover:bg-gray-100`}
                      >
                        <p className="text-left text-zinc-900 text-base font-normal font-poppins leading-normal">
                          {condition.Name}
                        </p>
                      </button>
                    ))
                  ) : (
                    <div className="p-2 flex flex-col space-y-4">
                      <div className="text-center text-black text-sm font-medium font-poppins leading-normal">
                        {t("noMatchesFound")}
                      </div>
                      <button
                        onClick={() => {
                          setInputValue("");
                          setSuggestionList([]);
                          setShowSuggestions(false);
                          setFormData({ PMCName: inputValue });
                          setShowAddCard(true);
                        }}
                        className="text-sky-700 text-base font-semibold font-poppins leading-tight"
                      >
                        {t("addNew")}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <div className="flex flex-row flex-wrap gap-4 mt-4">
            {medicalCondition.map((item) => (
              <div
                key={item?.PMCID}
                className="px-4 py-2.5 bg-sky-100 rounded-3xl"
              >
                <div className="text-sky-400 text-sm font-semibold font-poppins leading-none">
                  {item?.Name}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default MedicalCondition;