import React, { useState, useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import Tooltip from "../components/Tooltip";
import cross from "../assets/Images/PlatformManagement/cross.svg";
import tick from "../assets/Images/PlatformManagement/tick.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

function SubscriptionPlans() {
  const navigate = useNavigate();
  const {
    isSubscribed,
    currentPlan,
    perks,
    subscriptionPlans,
    loading: subsLoading,
  } = useSelector((state) => state.membership);
  const initialTab =
    currentPlan?.subscription?.subscriptionDetails?.clinicianSubscription
      ?.PlanCycle === "Yearly"
      ? 1
      : 0;
  const [selectedTab, setSelectedTab] = useState(initialTab);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const filteredPlans = subscriptionPlans.filter(
      (plan) => plan.PlanCycle === (selectedTab === 0 ? "Monthly" : "Yearly")
    );

    const enterprisePlan = {
      CPSID: 0,
      PlanName: "Enterprise",
      PlanPrice: "Let's talk",
      PlanCycle: selectedTab === 0 ? "Monthly" : "Yearly",
      Perks: perks.map((perk) => ({
        PerkID: perk.PerkID,
        PerkName: perk.Perks,
      })),
    };

    setPlans([...filteredPlans, enterprisePlan]);
  }, [selectedTab, subscriptionPlans, perks]);

  console.log("====================================");
  console.log(currentPlan);
  console.log("====================================");

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        Platform Management
      </div>
      <Breadcrumb />

      {/* My Subscription Plan */}
      {isSubscribed && (
        <div className="bg-white rounded-2xl shadow border border-gray-200 max-w-3xl mt-6">
          <div className=" flex flex-row items-center justify-between p-6">
            {/* Left Content */}
            <div>
              <div className=" text-neutral-600 text-sm font-medium font-poppins leading-tight">
                MY SUBSCRIPTION PLAN
              </div>
              <div className="text-zinc-900 text-lg font-medium font-poppins leading-relaxed">
                {
                  currentPlan?.subscription?.subscriptionDetails
                    ?.clinicianSubscription?.PlanName
                }
                :{" "}
                <span className="text-neutral-600 text-lg font-medium font-poppins leading-relaxed">
                  {parseInt(
                    currentPlan?.subscription?.subscriptionDetails
                      ?.clinicianSubscription?.PlanPrice
                  )}{" "}
                  CHF/
                  {currentPlan?.subscription?.subscriptionDetails
                    ?.clinicianSubscription?.PlanCycle === "Monthly"
                    ? "month"
                    : "year"}
                </span>
              </div>
              <div className="text-neutral-600 text-sm font-medium font-poppins leading-tight">
                Org Name : {currentPlan?.Clinic}.
              </div>
            </div>
            {/* line */}
            <div className="w-px h-24 bg-gray-200" />
            {/* Right Content */}
            <div>
              <div className="text-zinc-900 text-lg font-medium font-poppins leading-relaxed">
                Renew on{" "}
                {moment(
                  currentPlan?.subscription?.subscriptionDetails
                    ?.SubscriptionEndDate
                ).format("DD MMM, YYYY")}
              </div>
              <div>
                {currentPlan?.subscription?.subscriptionDetails
                  ?.SubscriptionType === "STRIPE" ? (
                  <div className="text-neutral-600 text-sm font-medium font-poppins leading-tight">
                    Payment Method :{" "}
                    <span className="text-zinc-900 text-sm font-medium font-poppins leading-tight">
                      {currentPlan?.subscription?.subscriptionDetails
                        ?.PaymentMethodBrand +
                        currentPlan?.subscription?.subscriptionDetails
                          ?.PaymentMethodType}
                    </span>
                    <span className="text-neutral-600 text-sm font-medium font-poppins leading-tight">
                      {" "}
                      :
                      {" ****" +
                        currentPlan?.subscription?.subscriptionDetails
                          ?.PaymentMethodLast4}
                    </span>
                  </div>
                ) : (
                  <div className="text-neutral-600 text-sm font-medium font-poppins leading-tight">
                    Payment Method :{" "}
                    <span className="text-zinc-900 text-sm font-medium font-poppins leading-tight">
                      Paper Invoice
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="text-gray-900 text-lg font-semibold font-poppins leading-relaxed mt-6">
        Plans that fit your scale
      </div>
      <div className="text-neutral-600 text-lg font-normal font-poppins leading-loose">
        Simple, transparent pricing that grows with you.
      </div>

      {/* Tab */}
      <div className="flex justify-center my-8">
        <div className="p-1 bg-neutral-50 rounded-2xl border border-gray-200 flex items-center space-x-2">
          <button
            onClick={() => setSelectedTab(0)}
            className={`px-3.5 py-2.5 ${
              selectedTab === 0
                ? "bg-white rounded-2xl shadow border border-sky-400"
                : ""
            } justify-center items-center gap-2 inline-flex`}
          >
            <div className="text-zinc-900 text-sm font-medium font-poppins leading-normal">
              Monthly
            </div>
          </button>
          <button
            onClick={() => setSelectedTab(1)}
            className={`px-3.5 py-2.5 ${
              selectedTab === 1
                ? "bg-white rounded-2xl shadow border border-sky-400"
                : ""
            } justify-center items-center gap-2 inline-flex`}
          >
            <div className="flex items-center justify-center space-x-2">
              <div className="text-zinc-900 text-sm font-medium font-poppins leading-normal">
                Yearly
              </div>
              <div className="px-2.5 py-0.5 bg-sky-100 rounded-2xl">
                <div className="text-center text-sky-700 text-sm font-medium font-poppins leading-tight">
                  Save 20%
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>

      {/* Cards */}
      {subsLoading ? (
        <div className="h-64 flex items-center justify-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="grid grid-cols-3 gap-10">
          {plans.map((plan) => {
            const isCurrentPlan =
              currentPlan?.subscription?.subscriptionDetails?.CPSID ===
              plan?.CPSID;

            const includedPerks = perks.filter((perk) =>
              plan.Perks.some((planPerk) => planPerk.PerkID === perk.PerkID)
            );
            const excludedPerks = perks.filter(
              (perk) =>
                !plan.Perks.some((planPerk) => planPerk.PerkID === perk.PerkID)
            );
            const sortedPerks = [...includedPerks, ...excludedPerks];

            const isBetterPlan =
              plan.PlanName === "Enterprise" ||
              parseInt(plan.PlanPrice) >
                parseInt(
                  currentPlan?.subscription?.subscriptionDetails
                    ?.clinicianSubscription?.PlanPrice
                );

            return (
              <div
                key={plan?.CPSID}
                className="w-full max-w-sm rounded-xl shadow-lg border border-gray-200 transform transition-transform duration-300 hover:-translate-y-2 hover:scale-105"
              >
                <div className="p-6">
                  <div className="flex items-center justify-between">
                    <div className="text-zinc-900 text-lg font-semibold font-poppins leading-loose">
                      {plan?.PlanName}
                    </div>
                    {isCurrentPlan ? (
                      <div className="px-2.5 py-0.5 bg-emerald-100 rounded-2xl">
                        <div className="text-center text-lime-700 text-sm font-medium font-poppins leading-tight">
                          Current Plan
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {plan.PlanName === "Enterprise" ? (
                    <div className="text-neutral-600 text-lg font-semibold font-poppins">
                      Let's Talk
                    </div>
                  ) : (
                    <div className="text-neutral-600 text-lg font-semibold font-poppins">
                      {parseInt(plan?.PlanPrice)} CHF /{" "}
                      <span className="text-neutral-600 text-lg font-normal font-poppins">
                        {plan?.PlanCycle}
                      </span>
                    </div>
                  )}
                </div>

                <div className="h-px bg-gray-200" />
                <div className="p-6">
                  <div className="text-zinc-900 text-sm font-semibold font-poppins leading-normal">
                    BENEFITS
                  </div>
                  {sortedPerks.map((perk) => (
                    <div
                      className="flex items-center space-x-2 mt-2"
                      key={perk.PerkID}
                    >
                      <div
                        className={`w-6 h-6 flex items-center justify-center rounded-xl ${
                          plan.Perks.some(
                            (planPerk) => planPerk.PerkID === perk.PerkID
                          )
                            ? "bg-emerald-100"
                            : "bg-red-100"
                        }`}
                      >
                        <img
                          src={
                            plan.Perks.some(
                              (planPerk) => planPerk.PerkID === perk.PerkID
                            )
                              ? tick
                              : cross
                          }
                          alt=""
                          className="w-4 h-4"
                        />
                      </div>
                      <div className="text-neutral-600 text-sm font-normal font-poppins leading-tight">
                        {perk.Perks}
                      </div>
                    </div>
                  ))}
                </div>

                {!isSubscribed ? (
                  <>
                    <div className="h-px bg-gray-200" />
                    <div className="p-6 flex items-center justify-center">
                      <button
                        onClick={() => {
                          if (plan.PlanName === "Enterprise") {
                            window.location.href =
                              "https://alleshealth.com/contactus";
                            return;
                          }

                          navigate("/payment-processing", {
                            state: { plan, isUpgrading: isSubscribed },
                          });
                        }}
                        className="w-full px-5 py-3 bg-white rounded-lg border border-sky-700 active:scale-95"
                      >
                        <div className="text-sky-700 text-center text-sm font-semibold font-poppins leading-normal">
                          {plan.PlanName === "Enterprise"
                            ? "Contact Us"
                            : `Subscribe to ${plan?.PlanName}`}
                        </div>
                      </button>
                    </div>
                  </>
                ) : (
                  !isCurrentPlan &&
                  isBetterPlan && (
                    <>
                      <div className="h-px bg-gray-200" />
                      <div className="p-6 flex items-center justify-center">
                        <button
                          onClick={() => {
                            if (plan.PlanName === "Enterprise") {
                              window.location.href =
                                "https://alleshealth.com/contactus";
                              return;
                            }

                            navigate("/payment-processing", {
                              state: { plan, isUpgrading: isSubscribed },
                            });
                          }}
                          className="w-full px-5 py-3 bg-white rounded-lg border border-sky-700 active:scale-95"
                        >
                          <div className="text-sky-700 text-center text-sm font-semibold font-poppins leading-normal">
                            {plan.PlanName === "Enterprise"
                              ? "Contact Us"
                              : `Upgrade to ${plan?.PlanName}`}
                          </div>
                        </button>
                      </div>
                    </>
                  )
                )}
              </div>
            );
          })}
        </div>
      )}

      {false && (
        <>
          <div className="text-zinc-900 text-lg font-semibold font-poppins leading-relaxed mt-7">
            Billing Details
          </div>

          <div className="w-full mx-auto mt-4 shadow rounded-md">
            <table className="min-w-full">
              <thead>
                <tr className="text-left bg-gray-50 border-b border-gray-200">
                  <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                    Date
                  </th>
                  <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                    Details
                  </th>
                  <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                    Amount
                  </th>
                  <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3].map((user) => (
                  <tr className="border-b border-gray-200 hover:bg-sky-50">
                    <td className="p-4 text-zinc-900 text-sm font-normal font-poppins leading-tight">
                      10th Feb, 2024
                    </td>
                    <td className="p-4">
                      <div className="text-zinc-900 text-sm font-normal font-poppins leading-tight">
                        Standard Monthly Plan
                      </div>
                      <div className="w-40 text-zinc-900 text-xs font-normal font-poppins leading-tight">
                        3000 CHF/month +taxes
                      </div>
                    </td>
                    <td className="p-4text-zinc-900 text-sm font-normal font-poppins leading-tight">
                      3024.30 CHF
                    </td>

                    <td className="p-4">
                      <div className="flex flex-row items-center space-x-1">
                        <Tooltip message="View">
                          <button className="p-1 rounded-full group group-hover:bg-sky-100">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 text-sky-500"
                            >
                              <path
                                d="M21.3448 11.045C21.6488 11.4713 21.8008 11.6845 21.8008 12C21.8008 12.3155 21.6488 12.5287 21.3448 12.955C19.9787 14.8706 16.49 19 11.8008 19C7.11156 19 3.62288 14.8706 2.25682 12.955C1.95279 12.5287 1.80078 12.3155 1.80078 12C1.80078 11.6845 1.95279 11.4713 2.25682 11.045C3.62288 9.12944 7.11156 5 11.8008 5C16.49 5 19.9787 9.12944 21.3448 11.045Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                              />
                              <path
                                d="M14.8008 12C14.8008 10.3431 13.4577 9 11.8008 9C10.1439 9 8.80078 10.3431 8.80078 12C8.80078 13.6569 10.1439 15 11.8008 15C13.4577 15 14.8008 13.6569 14.8008 12Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                              />
                            </svg>
                          </button>
                        </Tooltip>
                        <div className="text-sky-500 text-sm font-semibold font-poppins leading-none">
                          View Invoice
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default SubscriptionPlans;
