import React, { useEffect, useState } from "react";
import { RxDotsHorizontal } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import profilePic from "../assets/Images/TelehealthDashboard/profilePic.png";
import noPatientsDataForToday from "../assets/Images/TelehealthDashboard/noPatientsDataForToday.png";
import telehealthPic from "../assets/Images/TelehealthDashboard/telehealthPic.png";
import inClinicPic from "../assets/Images/TelehealthDashboard/inClinicPic.png";
import { getBucketURL } from "../utils/regex";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import expandIcons1 from "../assets/Images/TelehealthDashboard/expandIcons1.png";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import { useTranslation } from 'react-i18next';

const TodayPatientList = ({ onHideTile1 }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [patientsData, setPatientsData] = useState([]);
  const { dashboard } = useSelector((state) => state.dashboard);
  const [showHideTile, setShowHideTile] = useState(false);
  const [patientListType, setPatientListType] = useState("Today");
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      color: "#3CA5DC",
    },
  ]);

  const fetchBookedPatients = async () => {
    if (dashboard) {
      let patientsForTargetDate = [];
      let targetDate = moment();

      if (patientListType === "Today") {
        patientsForTargetDate =
          dashboard[targetDate.format("YYYY-MM-DD")] || [];
      } else if (patientListType === "Tomorrow") {
        targetDate = targetDate.add(1, "days");
        patientsForTargetDate =
          dashboard[targetDate.format("YYYY-MM-DD")] || [];
      } else if (patientListType === "ThisWeek") {
        const startOfWeek = moment().startOf("week");
        for (let i = 0; i < 7; i++) {
          const weekDay = startOfWeek.clone().add(i, "days");
          const weekDayData = dashboard[weekDay.format("YYYY-MM-DD")] || [];
          patientsForTargetDate = patientsForTargetDate.concat(weekDayData);
        }
      } else if (patientListType === "dateRange") {
        const start = moment(dateRange[0].startDate);
        const end = moment(dateRange[0].endDate);
        for (
          let m = moment(start);
          m.diff(end, "days") <= 0;
          m.add(1, "days")
        ) {
          const dayData = dashboard[m.format("YYYY-MM-DD")] || [];
          patientsForTargetDate = patientsForTargetDate.concat(dayData);
        }
      }

      const formattedPatientsData = await Promise.all(
        patientsForTargetDate.map(async (patient) => {
          const profileImageUrl = await getBucketURL(
            patient.Patient_Info.ProfileImageUrl
          ).catch(() => profilePic);
          const appointmentTime = moment(patient.Session_Info.Start_time);

          return {
            id: patient.ID,
            IsMedicalConciergeMember:
              patient.Patient_Info.IsMedicalConciergeMember,
            MedicalConciergeJoiningDate:
              patient.Patient_Info.MedicalConciergeJoiningDate,
            AHUID: patient.Patient_Info.AHUID,
            Image: profileImageUrl || profilePic,
            appType: patient.Session_Info.AppointmentType,
            Name: patient.Patient_Info.Name,
            Age: patient.Patient_Info.Age,
            meetingID: patient.Session_Info.MeetingID,
            desc: `${patient.Patient_Info.Gender} - ${patient.Patient_Info.Age} ${t('years')}`,
            time: appointmentTime.format("h:mm A"),
            cond: "N/A",
            diagnosis: "-",
            note: patient.Session_Info.PersonalNote || "",
          };
        })
      );

      formattedPatientsData.sort(
        (a, b) => moment(a.time, "h:mm A") - moment(b.time, "h:mm A")
      );
      setPatientsData(formattedPatientsData);
    } else {
      setPatientsData([]);
    }
  };

  useEffect(() => {
    fetchBookedPatients();
  }, [dashboard, patientListType, dateRange]);

  return (
    <div
      className={`flex-1 basis-[40%] h-[47vh] p-4 rounded-lg shadow bg-[#FFFFFF] relative`}
    >
      <div className={`flex flex-col justify-center item-center`}>
        {/* Header */}
        <div className="flex flex-row justify-between items-center relative">
          {showHideTile ? (
            <div
              onClick={() => {
                setShowHideTile(false);
                onHideTile1("showTodayPatientList");
              }}
              className={`cursor-pointer flex justify-center items-center py-1 px-2 bg-[#E5F2FF] rounded-3xl`}
            >
              <h1
                className={`text-sky-400 text-[11.03px] font-semibold font-poppins`}
              >
                {t('hideTile')}
              </h1>
            </div>
          ) : (
            <div
              onClick={() => setShowHideTile(true)}
              className="cursor-pointer flex justify-center items-center bg-[#E5F2FF] rounded-full p-1 w-[30px] h-[30px]"
            >
              <RxDotsHorizontal className="text-sky-400 text-lg" />
            </div>
          )}
          <div className="text-zinc-900 text-lg font-semibold font-poppins">
            {t('patientList')}
          </div>

          {/* Date Range btn */}
          <button
            onClick={() => {
              setShowCalendar(!showCalendar);
            }}
            className="flex flex-row justify-center items-center"
          >
            <div className="text-sky-400 text-sm font-semibold font-poppins leading-none">
              {t('selectDateRange')}
            </div>
          </button>
          {showCalendar && (
            <div className="flex flex-col justify-center items-center gap-1 absolute top-[1.5rem] right-1 w-[21rem] shadow-lg rounded-md">
              <DateRange
                date={new Date()}
                shownDate={new Date()}
                onChange={(item) => {
                  setDateRange([item.selection]);
                  setPatientListType("dateRange");
                  setShowCalendar(false);
                }}
                ranges={dateRange}
                months={1}
                showDateDisplay={false}
                rangeColors={["#3CA5DC"]}
                direction="horizontal"
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                className=""
              />
            </div>
          )}
        </div>

        {/* Content */}
        <div className="flex flex-col w-full h-[36.5vh] my-4 justify-start items-center">
          {/* 3 Btns */}
          <div
            className={`flex flex-row justify-start items-center w-full gap-3 mb-4`}
          >
            <div
              onClick={() => setPatientListType("Today")}
              className={`cursor-pointer hover:scale-110 duration-500 flex justify-center items-center px-4 py-2.5 ${
                patientListType === "Today" ? "bg-sky-100" : "bg-gray-100"
              } rounded-3xl`}
            >
              <h1
                className={`${
                  patientListType === "Today"
                    ? "text-neutral-700"
                    : "text-neutral-600"
                } text-xs font-semibold font-poppins leading-none`}
              >
                {t('today')}
              </h1>
            </div>
            <div
              onClick={() => setPatientListType("Tomorrow")}
              className={`cursor-pointer hover:scale-110 duration-500 flex justify-center items-center px-4 py-2.5 ${
                patientListType === "Tomorrow" ? "bg-sky-100" : "bg-gray-100"
              } rounded-3xl`}
            >
              <h1
                className={`${
                  patientListType === "Tomorrow"
                    ? "text-neutral-700"
                    : "text-neutral-600"
                } text-xs font-semibold font-poppins leading-none`}
              >
                {t('tomorrow')}
              </h1>
            </div>
            <div
              onClick={() => setPatientListType("ThisWeek")}
              className={`cursor-pointer hover:scale-110 duration-500 flex justify-center items-center px-4 py-2.5 ${
                patientListType === "ThisWeek" ? "bg-sky-100" : "bg-gray-100"
              } rounded-3xl`}
            >
              <h1
                className={`${
                  patientListType === "ThisWeek"
                    ? "text-neutral-700"
                    : "text-neutral-600"
                } text-xs font-semibold font-poppins leading-none`}
              >
                {t('thisWeek')}
              </h1>
            </div>
          </div>

          {/* Patient List */}
          <div className="w-full overflow-y-auto scroll-smooth scrollbar scrollbar-thin scrollbar-thumb-[#3CA5DC] scrollbar-track-[#E5F2FF] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
            {patientsData.length !== 0 ? (
              patientsData?.map((patient) => (
                <div
                  key={patient.id}
                  className={`grid grid-cols-8 justify-between items-center w-full bg-white rounded-xl px-5 py-3 gap-4 mb-3 shadow`}
                >
                  <div className="col-span-3 flex flex-row justify-start items-center">
                    <div className="flex flex-row items-center justify-between">
                      <button
                        onClick={() => {
                          navigate("/patientDashboard/patientProfile", {
                            state: patient,
                          });
                        }}
                        className="flex items-center space-x-4 w-full"
                      >
                        <img
                          src={patient.Image}
                          alt={t('patientPic')}
                          className="w-10 h-10 rounded-full"
                        />
                        <div className="flex flex-col">
                          <h1 className="text-[#000000] text-[10.69px] font-medium font-poppins text-left">
                            {patient.Name}
                          </h1>
                          <h1 className="text-[#000000] text-xs font-normal font-poppins text-left">
                            {patient.desc}
                          </h1>
                        </div>
                      </button>
                    </div>
                  </div>

                  <div
                    className={`col-span-2 flex flex-col justify-center items-start w-full`}
                  >
                    <h1 className="text-[#8A8A8A] text-[9.87px] font-normal font-poppins">
                      {t('lastDiagnosis')}
                    </h1>
                    <h1 className="text-[#000000] text-xs font-normal font-poppins">
                      {patient.diagnosis}
                    </h1>
                  </div>

                  <div
                    className={`col-span-2 flex flex-col justify-center items-center w-full`}
                  >
                    {patient.appType === "TeleHealth" && (
                      <div
                        style={{ backgroundColor: "rgba(247, 160, 37, 0.15)" }}
                        className="flex justify-center items-center px-2 py-1 rounded-lg"
                      >
                        <h1 className="text-[#f7a025] text-[10.11px] font-normal font-poppins">
                          {patient.time}
                        </h1>
                      </div>
                    )}
                    {patient.appType === "In-Clinic" && (
                      <div
                        style={{ backgroundColor: "rgba(60, 165, 220, 0.15)" }}
                        className="flex justify-center items-center px-2 py-1 rounded-lg"
                      >
                        <h1 className="text-[#3ca5dc] text-[10.11px] font-normal font-poppins">
                          {patient.time}
                        </h1>
                      </div>
                    )}
                  </div>

                  <div className="flex flex-row justify-start items-center w-full">
                    {patient.appType === "TeleHealth" && (
                      <>
                        <div data-tooltip-id="TeleHealth">
                          <img
                            src={telehealthPic}
                            alt={t('telehealthPic')}
                            className="w-8 h-8 object-contain"
                          />
                        </div>
                        <Tooltip
                          id="TeleHealth"
                          place="top"
                          content={t('telehealth')}
                        />
                      </>
                    )}
                    {patient.appType === "In-Clinic" && (
                      <>
                        <div data-tooltip-id="In-Clinic">
                          <img
                            src={inClinicPic}
                            alt={t('inClinicPic')}
                            className="w-8 object-contain h-8"
                          />
                        </div>
                        <Tooltip
                          id="In-Clinic"
                          place="top"
                          content={t('inClinic')}
                        />
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div
                className={`flex flex-col justify-center items-center w-full px-4`}
              >
                <h1 className="text-[#A5A6A6] text-sm font-semibold font-poppins text-center mt-2">
                  {t('noPatientsAvailable')}
                </h1>
                <img
                  src={noPatientsDataForToday}
                  className="w-[20rem] h-full object-contain"
                  alt={t('noPatientsData')}
                />
              </div>
            )}
          </div>
        </div>

        {/* Full screen Icon */}
        {/* <div className="flex flex-row justify-end items-center w-full absolute bottom-2 right-4">
          <button
            className="text-sky-400 text-2xl active:scale-90 transition-transform border rounded-lg border-[#D1D1D1] w-7 h-7 p-1"
            onClick={() => {
              navigate("/clinicianDashboard/todaysPatientList");
            }}
          >
            <img
              src={expandIcons1}
              className="w-full h-full object-contain"
              alt={t('expandIcons')}
            />
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default TodayPatientList;