import React from "react";
import { Carousel } from "antd";
import '../App.css';
import { useTranslation } from 'react-i18next';

const AntdCarousel = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full md:w-[45rem] ml-0 md:ml-20">
      <Carousel autoplay>
        <div className="text-left mb-5 md:mb-10">
          <h5 className="w-full md:w-[95%] lg:w-[44rem] text-white text-xl md:text-[2.5rem] leading-6 md:leading-[62px] font-poppins font-bold">
            {t('streamlinePatientCare')}
          </h5>
        </div>
        <div className="text-left mb-5">
          <h5 className="w-full md:w-[95%] lg:w-[44rem] text-white text-xl md:text-[2.5rem] leading-6 md:leading-[62px] font-poppins font-bold">
            {t('revolutioniseTeleHealth')}
          </h5>
        </div>
        <div className="text-left mb-5">
          <h5 className="w-full md:w-[95%] lg:w-[44rem] text-white text-xl md:text-[2.5rem] leading-6 md:leading-[62px] font-poppins font-bold">
            {t('offerHealthcareServices')}
          </h5>
        </div>
      </Carousel>
    </div>
  );
};

export default AntdCarousel;