import { ChevronLast, ChevronFirst } from "lucide-react";
import { useContext, createContext, useState } from "react";
import AHlogo from "../assets/Images/logo.png";
import logoutImg from "../assets/Images/Sidebar/logout.svg";
import { logout } from "../services/operations/authAPI";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import navbarCollapseIcon from "../assets/Images/Sidebar/navbarCollapseIcon.png";
import navbarExpandIcon from "../assets/Images/Sidebar/navbarExpandIcon.png";
import { toggleSidebar, setSidebarExpanded } from "../slices/sidebarSlice";
import LanguageDropDown from "./LanguageDropDown";
import { useTranslation } from "react-i18next";

const SidebarContext = createContext();

export default function Sidebar({ children }) {
  const { settings } = useSelector((state) => state.masterData);
  const { logo } = useSelector((state) => state.profile);
  const { t } = useTranslation();
  const expanded = useSelector((state) => state.sidebar.expanded);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <aside className="h-[calc(100vh - 4rem)]">
      <nav className="h-full flex flex-col bg-white border-r shadow-sm">
        <div className="flex-1">
          <SidebarContext.Provider value={{ expanded }}>
            <ul className="px-3 mt-4">{children}</ul>
          </SidebarContext.Provider>
        </div>
        <LanguageDropDown expanded={expanded} />

        <button
          onClick={() => {
            dispatch(logout(navigate));
          }}
          className="flex items-center px-5 mb-5"
        >
          <img src={logoutImg} alt="" className="w-6 h-6" />
          <div
            className={`
              flex justify-between items-center
              overflow-hidden transition-all ${expanded ? "w-40 ml-2" : "w-0"}
          `}
          >
            <h1 className="text-rose-500 text-sm font-normal font-poppins">
              {t("logout")}
            </h1>
          </div>
        </button>
      </nav>
    </aside>
  );
}

export function SidebarItem({ icon, text, to, img, imgSelected }) {
  const { expanded } = useContext(SidebarContext);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isActive = () => {
    if (to) {
      // Check if the current route starts with the 'to' string
      const match = location.pathname.startsWith(to);
      return match;
    }
    return false;
  };

  const handleClick = () => {
    if (to) {
      // setExpanded(false);
      dispatch(setSidebarExpanded(false));
      navigate(to);
    }
  };

  return (
    <li
      className={`relative flex h-10 items-center justify-center py-2 px-3 rounded-md cursor-pointer transition-colors group
        ${
          isActive()
            ? "text-sky-400 bg-sky-100 font-semibold"
            : "text-black hover:bg-gray-100 font-normal"
        }
      ${expanded ? "mb-3" : "mb-3"}
      `}
      onClick={handleClick}
    >
      <img
        src={isActive() ? imgSelected : img}
        alt=""
        className="w-6 h-6 object-contain"
      />
      <div
        className={`overflow-hidden text-sm  font-poppins ${
          expanded ? " w-52 ml-3" : "w-0"
        }`}
      >
        {t(text)}
      </div>

      {!expanded && (
        <div
          className={`
      absolute left-full rounded-md px-2 py-1 ml-6 text-xs font-normal font-poppins z-50 text-white bg-sky-400
      invisible opacity-20 -translate-x-10 transition-all
      group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
    `}
        >
          {t(text)}
        </div>
      )}
    </li>
  );
}
