import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import language JSON files
import en from "./en.json";
import fr from "./fr.json";
import de from "./de.json";
import it from "./it.json";

i18n
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    resources: {
      en: { translation: en },
      fr: { translation: fr },
      de: { translation: de },
      it: { translation: it },
    },
    lng: "en", // Default language
    // fallbackLng: "en",
    interpolation: {
      escapeValue: false, // React already escapes content
    },
  });

export default i18n;
