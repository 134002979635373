import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { localizationLanguageList } from "../data/lang";
import { setLanguage } from "../slices/languageSlice";
import { useTranslation } from "react-i18next";

export default function LanguageDropDown({ expanded }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.language);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const changeLanguage = (lng) => {
    dispatch(setLanguage(lng));
  };

  useOnClickOutside(ref, () => setOpen(false));

  const selectedLanguage = localizationLanguageList.find(
    (lang) => lang.code === language
  );

  return (
    <>
      <button
        disabled={!expanded}
        className={`relative mb-5 px-3 w-full`}
        onClick={() => setOpen(true)}
      >
        {expanded && (
          <div className="text-zinc-950 text-xs font-semibold text-left font-poppins mb-2 transition-all duration-200">
            {t("selectLanguage")}
          </div>
        )}
        <div
          className={`${expanded ? "bg-gray-200" : "bg-white"} rounded-md p-4`}
        >
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center space-x-2">
              <img
                src={selectedLanguage.icon}
                className={`w-6 h-6 rounded-full object-contain`}
              />
              {expanded && (
                <div className="text-zinc-900 text-sm font-normal font-poppins">
                  {selectedLanguage.name}
                </div>
              )}
            </div>
            {expanded && (
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`w-6 h-6 text-sky-400 ${
                    open
                      ? "transform rotate-180 transition-transform duration-200"
                      : "transform rotate-0 transition-transform duration-200"
                  }`}
                >
                  <path
                    d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>

        {open && (
          <div
            onClick={(e) => e.stopPropagation()}
            ref={ref}
            className=" bg-white rounded shadow-2xl p-4 z-50 overflow-hidden"
          >
            <div className="flex flex-col items-center">
              {localizationLanguageList.map((lang) => (
                <div
                  onClick={() => {
                    changeLanguage(lang.code);
                    setOpen(false);
                  }}
                  key={lang.id}
                  className="flex flex-row items-center justify-between w-full p-2 cursor-pointer rounded-md hover:bg-gray-100"
                >
                  <div className="flex flex-row items-center space-x-2">
                    <img
                      src={lang.icon}
                      className={`w-6 h-6 rounded-full object-cover`}
                    />
                    <div className="text-zinc-900 text-sm font-normal font-poppins">
                      {lang.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </button>
    </>
  );
}
