import moment from "moment";
import React, { useState } from "react";
import { LuPen } from "react-icons/lu";
import ModalComponent from "./Modal";
import PhoneInput from "react-phone-input-2";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { set } from "date-fns";
import { updatePatientProfile } from "../services/operations/patientManagementAPI";
import Tooltip from "./Tooltip";
import EditUserDetailsModal from "./EditUserDetailsModal";
import { useTranslation } from 'react-i18next';

function UserDetails({
  hasEditAccess,
  patient,
  patientProfile,
  showEditButton = true,
  navigateTo,
}) {
  const { t } = useTranslation();
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showEditComp, setShowEditComp] = useState(false);

  return (
    <div className="relative">
      <div className="max-w-2xl">
        <div className="flex flex-row justify-between items-center w-full gap-1">
          <h1 className="text-[#101828] text-lg font-medium font-poppins leading-normal">
            {t('userDetail')}
          </h1>
          {showEditButton && hasEditAccess && (
            <>
              <button
                onClick={() => setShowEditComp(true)}
                className="absolute right-0 top-0 active:scale-95"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                    stroke="#545454"
                    strokeWidth="1.5"
                    stroke-linecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                    stroke="#545454"
                    strokeWidth="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
              {showEditComp && (
                <EditUserDetailsModal
                  patient={patient}
                  patientProfile={patientProfile}
                  navigateTo={navigateTo}
                  setShowEditComp={setShowEditComp}
                />
              )}
            </>
          )}
        </div>
        <div className="grid grid-cols-3 gap-2 my-4">
          <div>
            <p className="text-zinc-500 text-xs font-normal font-poppins">
              {t('dateOfBirth')}
            </p>
            <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
              {moment(patientProfile?.DOB).format("DD MMM YYYY")}
            </p>
          </div>
          <div>
            <p className="text-zinc-500 text-xs font-normal font-poppins">
              {t('phoneNumber')}
            </p>
            <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
              {patientProfile?.Phone}
            </p>
          </div>
          <div>
            <p className="text-zinc-500 text-xs font-normal font-poppins">
              {t('email')}
            </p>
            <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1 truncate">
              {patientProfile?.Email}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-2 mb-4">
          <div>
            <p className="text-zinc-500 text-xs font-normal font-poppins">
              {t('occupation')}
            </p>
            <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
              {patientProfile?.Occupation || "-"}
            </p>
          </div>
          <div>
            <p className="text-zinc-500 text-xs font-normal font-poppins">
              {t('employment')}
            </p>
            <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
              {patientProfile?.Employment || "-"}
            </p>
          </div>
          <div>
            <p className="text-zinc-500 text-xs font-normal font-poppins">
              {t('livingArrangement')}
            </p>
            <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
              {patientProfile?.LivingArrangement || "-"}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-2 mb-4">
          <div>
            <p className="text-zinc-500 text-xs font-normal font-poppins">
              {t('relationshipStatus')}
            </p>
            <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
              {patientProfile?.RelationshipStatus || "-"}
            </p>
          </div>
          <div>
            <p className="text-zinc-500 text-xs font-normal font-poppins">
              {t('recentActivity')}
            </p>
            <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
              {patientProfile?.LastConsultationDate
                ? moment(patientProfile.LastConsultationDate).format(
                    "DD MMM YYYY"
                  )
                : "-"}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2 mb-4">
          <div>
            <p className="text-zinc-500 text-xs font-normal font-poppins">
              {t('emergencyContactDetails')}
            </p>
            <div className="flex flex-row items-center space-x-2 mt-1">
              <p className=" text-zinc-900 text-xs font-semibold font-poppins">
                {/* {patientProfile?.EmergencyContact || "-"} */}
                Zane Shroffing
              </p>
              <Tooltip
                message={patientProfile?.Phone || t('noPhoneNumberAvailable')}
                // activation="click"
                showCopyButton={true}
              >
                <button className="p-1 rounded-full group group-hover:bg-sky-100">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                  >
                    <path
                      d="M9.15826 5.71223L8.7556 4.80625C8.49232 4.21388 8.36068 3.91768 8.16381 3.69101C7.91708 3.40694 7.59547 3.19794 7.23568 3.08785C6.94859 3 6.62446 3 5.97621 3C5.02791 3 4.55376 3 4.15573 3.18229C3.68687 3.39702 3.26344 3.86328 3.09473 4.3506C2.95151 4.76429 2.99254 5.18943 3.07458 6.0397C3.94791 15.0902 8.90982 20.0521 17.9603 20.9254C18.8106 21.0075 19.2358 21.0485 19.6494 20.9053C20.1368 20.7366 20.603 20.3131 20.8178 19.8443C21 19.4462 21 18.9721 21 18.0238C21 17.3755 21 17.0514 20.9122 16.7643C20.8021 16.4045 20.5931 16.0829 20.309 15.8362C20.0824 15.6393 19.7862 15.5077 19.1938 15.2444L18.2878 14.8417C17.6463 14.5566 17.3255 14.4141 16.9996 14.3831C16.6876 14.3534 16.3731 14.3972 16.0811 14.5109C15.776 14.6297 15.5064 14.8544 14.967 15.3038C14.4302 15.7512 14.1618 15.9749 13.8338 16.0947C13.543 16.2009 13.1586 16.2403 12.8524 16.1951C12.5069 16.1442 12.2424 16.0029 11.7133 15.7201C10.0673 14.8405 9.15953 13.9328 8.27987 12.2867C7.99714 11.7577 7.85578 11.4931 7.80487 11.1477C7.75974 10.8414 7.79908 10.457 7.9053 10.1663C8.02512 9.83828 8.24881 9.56986 8.69619 9.033C9.14562 8.49368 9.37034 8.22402 9.48915 7.91891C9.60285 7.62694 9.64662 7.3124 9.61695 7.00048C9.58594 6.67452 9.44338 6.35376 9.15826 5.71223Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </button>
              </Tooltip>

              <Tooltip
                message={patientProfile?.Email || t('noEmailAvailable')}
                // activation="click"
                showCopyButton={true}
              >
                <button className="p-1 rounded-full group group-hover:bg-sky-100">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                  >
                    <path
                      d="M2 6L8.91302 9.91697C11.4616 11.361 12.5384 11.361 15.087 9.91697L22 6"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.01577 13.4756C2.08114 16.5412 2.11383 18.0739 3.24496 19.2094C4.37608 20.3448 5.95033 20.3843 9.09883 20.4634C11.0393 20.5122 12.9607 20.5122 14.9012 20.4634C18.0497 20.3843 19.6239 20.3448 20.7551 19.2094C21.8862 18.0739 21.9189 16.5412 21.9842 13.4756C22.0053 12.4899 22.0053 11.5101 21.9842 10.5244C21.9189 7.45886 21.8862 5.92609 20.7551 4.79066C19.6239 3.65523 18.0497 3.61568 14.9012 3.53657C12.9607 3.48781 11.0393 3.48781 9.09882 3.53656C5.95033 3.61566 4.37608 3.65521 3.24495 4.79065C2.11382 5.92608 2.08114 7.45885 2.01576 10.5244C1.99474 11.5101 1.99475 12.4899 2.01577 13.4756Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </Tooltip>
            </div>
          </div>
          <div className="col-span-2">
            <p className="text-zinc-500 text-xs font-normal font-poppins">
              {t('address')}
            </p>
            <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
              {patientProfile?.Address || "-"}
            </p>
          </div>
        </div>

        <div className="">
          <p className="text-zinc-500 text-xs font-normal font-poppins">
            {t('details')}
          </p>
          <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
            {patientProfile?.Details || "-"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;