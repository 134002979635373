import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { partnerLogin } from "../services/operations/authAPI";
import { SlLogin } from "react-icons/sl";
import reqDemoIcon from "../assets/Images/reqDemoIcon.svg";
import CheckboxIcon from "./CheckBoxIcon";
import { useTranslation } from 'react-i18next';
import toast from "react-hot-toast";

function PartnerLoginForm() {
  const { t } = useTranslation();
  const savedInfo = localStorage.getItem("savedInfo")
    ? JSON.parse(localStorage.getItem("savedInfo"))
    : "";
  const [loginType, setLoginType] = useState("0");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    Email: savedInfo.Email ? savedInfo.Email : "",
    Phone: savedInfo.Phone ? savedInfo.Phone : "",
    Password: savedInfo ? savedInfo.Password : "",
    rememberMe: savedInfo ? savedInfo.RememberMe : false,
  });

  const handleLoginTypeChange = (type) => {
    setLoginType(type);
  };

  const handleOnChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (loginType === "0") {
      if (!formData.Email.includes("@")) {
        toast.error(t('validEmailError'));
        return;
      }
    } else if (loginType === "1") {
      if (formData.Phone.length !== 10) {
        toast.error(t('validPhoneError'));
        return;
      }
    }

    if (loginType === "0") {
      dispatch(
        partnerLogin(
          formData.Email,
          formData.Password,
          loginType,
          formData.rememberMe,
          navigate
        )
      );
    } else if (loginType === "1") {
      dispatch(
        partnerLogin(
          formData.Phone,
          formData.Password,
          loginType,
          formData.rememberMe,
          navigate
        )
      );
    }
  };

  return (
    <div className="bg-white w-full h-[90vh] flex flex-col justify-start">
      <div className="flex items-center justify-start px-20 gap-3 mt-7 w-full">
        <Link
          to="/login"
          className="bg-sky-400 rounded-xl py-2 px-6 flex flex-row items-center justify-center space-x-2"
        >
          <div className="text-white text-xs font-semibold font-poppins">
            {t('clinicLogin')}
          </div>
          <SlLogin className="text-white font-semibold" size={20} />
        </Link>
        <button
          onClick={() =>
            window.open("https://alleshealth.com/contactus", "_blank")
          }
          className="bg-white rounded-xl py-2 px-6 border border-sky-400 flex flex-row items-center justify-center space-x-2"
        >
          <div className="text-sky-600 text-xs font-semibold font-poppins">
            {t('requestDemo')}
          </div>
          <div className="">
            <img
              src={reqDemoIcon}
              alt={t('demoIcon')}
              className="w-5 h-5 object-contain"
            />
          </div>
        </button>
      </div>

      <div className="px-20 mt-28">
        {/* login */}
        <div className="text-zinc-900 text-3xl font-semibold font-poppins leading-relaxed">
          {t('partnerLogin')}
        </div>
        <div className="text-neutral-600 text-xs font-normal font-poppins leading-relaxed mt-3">
          {t('loginForPartners')}
        </div>

        <div className="mt-14 flex flex-row items-center space-x-5">
          <div className="flex flex-col justify-center">
            <button
              className={`px-2 py-1 flex flex-row items-center justify-center space-x-1 rounded-3xl ${
                loginType === "0"
                  ? "bg-sky-400 text-white"
                  : "bg-sky-100 text-sky-500"
              } `}
              onClick={() => handleLoginTypeChange("0")}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`${
                  loginType === "0" ? "text-white" : "text-sky-500"
                } `}
              >
                <path
                  d="M1.84766 3.91357L5.46838 5.9651C6.80321 6.72142 7.36719 6.72142 8.70202 5.9651L12.3227 3.91357"
                  stroke="currentColor"
                  strokeWidth="0.741636"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.85592 7.82779C1.89015 9.43341 1.90728 10.2362 2.49971 10.8309C3.09214 11.4256 3.91666 11.4462 5.5657 11.4877C6.58203 11.5132 7.58837 11.5132 8.60471 11.4877C10.2538 11.4462 11.0782 11.4256 11.6707 10.8309C12.2631 10.2362 12.2803 9.43341 12.3145 7.82779C12.3255 7.31152 12.3255 6.79835 12.3145 6.28209C12.2803 4.6765 12.2631 3.8737 11.6707 3.27902C11.0782 2.68433 10.2538 2.66362 8.60471 2.62218C7.58837 2.59664 6.58203 2.59664 5.56569 2.62218C3.91666 2.66361 3.09214 2.68432 2.4997 3.27901C1.90727 3.8737 1.89015 4.67649 1.85591 6.28209C1.8449 6.79835 1.84491 7.31152 1.85592 7.82779Z"
                  stroke="currentColor"
                  strokeWidth="0.741636"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="text-xs font-medium font-poppins">{t('email')}</div>
            </button>
            <div
              className={`h-px mt-1 ${
                loginType === "0" ? "bg-sky-500" : "bg-white"
              }`}
            />
          </div>
          <div className="flex flex-col justify-center">
            <button
              className={`px-2 py-1 flex flex-row items-center justify-center space-x-1 rounded-3xl ${
                loginType === "1"
                  ? "bg-sky-400 text-white"
                  : "bg-sky-100 text-sky-500"
              } `}
              onClick={() => handleLoginTypeChange("1")}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`${
                  loginType === "1" ? "text-white" : "text-sky-500"
                }`}
              >
                <path
                  d="M1.99418 5.65636C1.54731 4.87713 1.33153 4.24085 1.20143 3.59588C1.009 2.64198 1.44936 1.71017 2.17885 1.1156C2.48716 0.864318 2.84059 0.950174 3.02291 1.27725L3.43451 2.01567C3.76075 2.60097 3.92387 2.89361 3.89152 3.20387C3.85917 3.51413 3.63918 3.76682 3.19919 4.27221L1.99418 5.65636ZM1.99418 5.65636C2.89871 7.23355 4.31818 8.65381 5.89719 9.55938M5.89719 9.55938C6.67642 10.0062 7.31269 10.222 7.95768 10.3521C8.91156 10.5446 9.84338 10.1042 10.4379 9.37469C10.6892 9.06641 10.6034 8.71297 10.2763 8.53064L9.53788 8.11903C8.95257 7.79279 8.65994 7.62969 8.34968 7.66203C8.03941 7.69437 7.78671 7.91436 7.28134 8.35434L5.89719 9.55938Z"
                  stroke="currentColor"
                  strokeWidth="0.707068"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="text-xs font-medium font-poppins">{t('phone')}</div>
            </button>
            <div
              className={`h-px mt-1 ${
                loginType === "1" ? "bg-sky-500" : "bg-white"
              }  `}
            />
          </div>
        </div>

        {/* login form */}
        <div className="mt-6">
          <form onSubmit={handleOnSubmit}>
            {loginType === "0" ? (
              <>
                {/* Email */}
                <div className="mb-9">
                  <label>
                    <input
                      required
                      type="text"
                      name="Email"
                      value={loginType === "0" ? formData.Email : ""}
                      onChange={handleOnChange}
                      placeholder={t('inputEmail')}
                      className={`w-full text-black text-xs font-medium font-poppins p-4 bg-white rounded-xl border border-neutral-300 focus:outline-none`}
                    />
                  </label>
                </div>
              </>
            ) : (
              <>
                {/* Phone Number */}
                <div className="mb-9">
                  <label>
                    <input
                      required
                      type="tel"
                      inputMode="tel"
                      maxLength={10}
                      name="Phone"
                      value={loginType === "1" ? formData.Phone : ""}
                      onChange={handleOnChange}
                      placeholder={t('inputPhone')}
                      className={`w-full text-black text-xs font-medium font-poppins p-4 bg-white rounded-xl border border-neutral-300 focus:outline-none`}
                    />
                  </label>
                </div>
              </>
            )}

            {/* password */}
            <div className="relative">
              <label>
                <input
                  className={`w-full text-black text-xs font-medium font-poppins bg-[#ffffff] rounded-2xl p-4 border border-[#CFCFCF] focus:outline-none`}
                  required
                  type={showPassword ? "text" : "password"}
                  name="Password"
                  value={formData.Password}
                  onChange={handleOnChange}
                  placeholder={t('inputPassword')}
                />
              </label>
              <span
                onClick={() => setShowPassword((prev) => !prev)}
                className="absolute right-4 top-6 transform -translate-y-1/2 z-10 cursor-pointer"
              >
                {showPassword ? (
                  <AiOutlineEyeInvisible fontSize={24} fill="#3CA5DC" />
                ) : (
                  <AiOutlineEye fontSize={24} fill="#3CA5DC" />
                )}
              </span>
            </div>

            <div className="flex flex-row justify-between items-center w-full mt-3 mb-8">
              {/* remember me */}
              <label className="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  className="hidden"
                  checked={formData.rememberMe}
                  onChange={handleOnChange}
                />
                <CheckboxIcon checked={formData.rememberMe} />
                <span className="text-zinc-500 text-xs font-medium font-poppins">
                  {t('rememberMe')}
                </span>
              </label>

              <div className="flex justify-start">
                <Link
                  to="/forgot-password"
                  className="text-sky-500 text-xs font-semibold font-poppins"
                >
                  {t('forgotPassword')}
                </Link>
              </div>
            </div>

            {/* login button */}
            <button
              type="submit"
              className="w-full bg-sky-400 rounded-xl border text-white text-sm font-medium font-poppins py-3 focus:outline-none hover:bg-sky-500 transition"
            >
              {t('login')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PartnerLoginForm;