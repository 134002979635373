import styles from "./styles.module.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import { FaList } from "react-icons/fa6";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import UserDetails from "../components/UserDetails";
import inPersonIcon from "../assets/Images/BookAppointment/inPersonIcon.png";
import ModalComponent from "../components/Modal";
import RadioButton from "../components/RadioButton/RadioButton";
import toast from "react-hot-toast";
import moment from "moment";
import breadcrumbsArrow from "../assets/Images/breadcrumbsArrow.png";
import Breadcrumb from "../components/Breadcrumb";
import deleteModalIcon from "../assets/Images/OurServices/deleteModalIcon.png";
import Pagination from "../components/Pagination";

function ArchivePatients() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [showComplianceSummaryModal, setShowComplianceSummaryModal] =
    useState(false);
  const { token } = useSelector((state) => state.auth);
  const { archivedPatients, loading: patientLoading } = useSelector(
    (state) => state.patient
  );
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  if (patientLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
        {/* Header Section */}

        <div className="text-zinc-900 text-2xl font-medium font-poppins">
        Archives Patient
        </div>
        <Breadcrumb />
        <div className="flex flex-col items-start justify-center mt-6">
          <h1 className="text-[#263238] text-base font-medium font-poppins leading-7">
            Deleted Patient Data in compliance with GDPR
          </h1>
          <h1 className="text-[#545454] text-base font-normal font-poppins leading-7">
            In here you can see all the Patient's whose data is yet to be
            Deleted as per GDPR Compliance
          </h1>
        </div>

        {/* Search and Compliance Btn */}
        <div className="flex flex-row justify-between items-center w-full gap-4 mt-6">
          <label
            className={`flex-1 flex flex-row items-center justify-start bg-[#F2F3F4] rounded-[20px] w-full py-2 px-4 gap-4`}
          >
            <IoIosSearch className="text-[#8A8A8A] w-5 h-5" />
            <input
              type="text"
              placeholder="Search for Patients"
              onChange={handleSearch}
              className={`bg-[#F2F3F4] text-sm w-full text-[#8A8A8A] font-normal font-poppins focus:outline-none focus:border-transparent`}
            />
          </label>

          <button
            onClick={() => {
              setShowComplianceSummaryModal(true);
            }}
            className="text-[#007AAE] text-sm font-medium font-poppins underline leading-5"
          >
            Compliance Summary
          </button>
        </div>

        <div className="flex flex-col justify-center items-center mt-10">
          {/* Table Header */}
          <div className="mt-4 grid grid-cols-5 justify-between items-center w-full bg-[#F7FBFE] py-3 px-6 mb-[14px]">
            <div className="col-span-1 flex flex-row justify-start items-center gap-1">
              <h1 className="text-[#263238] text-sm font-medium font-poppins leading-[20px]">
                Patient Name
              </h1>
            </div>
            <div className="col-span-3 flex flex-row justify-start items-center gap-1">
              <h1 className="text-[#263238] text-sm font-medium font-poppins leading-[20px]">
                Deleted Data
              </h1>
            </div>
            <div className="col-span-1 flex flex-row justify-start items-center gap-1">
              <h1 className="text-[#263238] text-sm font-medium font-poppins leading-[20px]">
                Date of Deletion
              </h1>
            </div>
          </div>

          {/* Table Content */}
          {archivedPatients?.length === 0 && (
            <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins mt-4 w-full text-center">
              No patients found
            </h1>
          )}
          {archivedPatients?.map((patient) => (
            <React.Fragment key={patient?.AHUID}>
              <div className="grid grid-cols-5 justify-between items-center w-full bg-[#FAFAFA] py-4 px-6 mb-[6px]">
                <div className="col-span-1 flex flex-row justify-start items-center gap-3">
                  <img
                    src={patient?.Image}
                    alt={patient?.FirstName}
                    className="w-10 h-10 object-cover rounded-full"
                  />
                  <div className="flex flex-col justify-center items-start gap-1">
                    <h1 className="text-[#101828] text-sm font-medium font-poppins leading-[20px]">
                      {patient?.FirstName} {patient?.LastName}
                    </h1>
                    <h1 className="text-[#8A8A8A] text-sm font-normal font-poppins leading-[20px]">
                      {patient?.Gender},{" "}
                      {moment().diff(
                        moment(patient?.DOB, "YYYY-MM-DD"),
                        "years"
                      )}
                    </h1>
                  </div>
                </div>
                <div className="col-span-3 flex flex-row justify-start items-center gap-1">
                  {[
                    "Patient Detail",
                    "Reports",
                    "Diagnosis",
                    "Treatments",
                    "Appointments",
                    "Invoices",
                    "Prescription",
                  ].map((text, index) => (
                    <div
                      key={index}
                      className="flex flex-col justify-center items-center bg-[#F3F5F7] rounded-2xl py-2 px-2"
                    >
                      <h1 className="text-[#545454] text-[10px] font-semibold font-poppins">
                        {text}
                      </h1>
                    </div>
                  ))}
                </div>
                <div className="col-span-1 flex flex-row justify-start items-center gap-1">
                  <h1 className="text-[#545454] text-xs font-medium font-poppins">
                    {moment(patient?.DeletedAt).format("DD/MM/YYYY")}
                  </h1>
                </div>
              </div>
            </React.Fragment>
          ))}

          <h1 className="text-[#263238] text-[10px] font-normal font-poppins leading-3 w-full text-center my-10">
            Need Deleted Data? Contact Us at :
            <a href="mailto:info@alleshealth.com" className="ml-1 underline">
              info@alleshealth.com
            </a>
          </h1>

          <Pagination
            totalItems={dummy?.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />

          {/* Compliance Summary MODAL */}
          <ModalComponent
            show={showComplianceSummaryModal}
            handleClose={() => {
              setShowComplianceSummaryModal(false);
            }}
            outSideModalPress={false}
          >
            <div className="w-[875px] relative">
              <div className="flex flex-col items-center justify-between">
                <div className="text-[#263238] text-base font-semibold font-poppins text-center mt-5">
                  GDPR Compliance Rules & Regulations
                </div>

                <div className="h-[0.34px] w-full bg-[#3CA5DC] my-6"></div>

                <div className="overflow-y-scroll max-h-[55vh] scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                  {data.map((item, index) => (
                    <div key={index} style={{ marginBottom: "20px" }}>
                      {/* Add index + 1 to show numbers */}
                      <h2 className="text-[11px] font-normal font-poppins text-black leading-[16.5px]">
                        {/* Display number before title */}
                        {index + 1}. {item.title}
                      </h2>
                      <ul className="list-disc list-inside pl-4">
                        {/* Display bullet points for subitems */}
                        {item.subItems.map((subItem, subIndex) => (
                          <li
                            style={{ listStyle: "disc" }}
                            key={subIndex}
                            className="text-[11px] font-normal font-poppins list-disc text-black leading-[16.5px]"
                          >
                            {subItem}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>

                <div className="flex flex-row items-center justify-center mt-12 space-x-4 w-full">
                  <button
                    onClick={() => {
                      setShowComplianceSummaryModal(false);
                    }}
                    className="w-44 px-2 py-3 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
                  >
                    <div className="text-[#007AAE] text-sm font-semibold font-poppins leading-normal">
                      Go Back
                    </div>
                  </button>
                  <button
                    onClick={(e) => {
                      setShowComplianceSummaryModal(false);
                      window.open("https://gdpr-info.eu/", "_blank");
                    }}
                    className="w-44 px-2 py-3 flex items-center justify-center bg-[#3CA5DC] rounded-lg border border-[#3CA5DC] active:scale-95"
                  >
                    <div className="text-white text-sm font-semibold font-poppins leading-normal">
                      See full Policy
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </ModalComponent>
        </div>
      </div>
  );
}

export default ArchivePatients;

const data = [
  {
    title: "Lawfulness, Fairness, and Transparency",
    subItems: [
      "Lawful Processing: Personal data must be processed lawfully, fairly, and transparently.",
      "Consent: Obtain clear and explicit consent from individuals before processing their data, except in cases of lawful exemptions.",
      "Transparency: Provide clear information about data collection, processing purposes, and individuals' rights at the time of data collection.",
    ],
  },
  {
    title: "Purpose Limitation",
    subItems: [
      "Specified Purposes: Data must be collected for specified, explicit, and legitimate purposes.",
      "Restricted Use: Do not process data in a manner incompatible with the purposes for which it was collected, unless additional consent is obtained.",
    ],
  },
  {
    title: "Data Minimization",
    subItems: [
      "Adequacy: Ensure that the personal data collected is adequate, relevant, and limited to what is necessary for the intended purposes.",
      "Minimized Data Collection: Collect only the data necessary to fulfill the specified purpose.",
    ],
  },
  {
    title: "Accuracy",
    subItems: [
      "Accuracy of Data: Personal data must be accurate and, where necessary, kept up to date.",
      "Rectification: Take reasonable steps to erase or rectify inaccurate data promptly.",
    ],
  },
  {
    title: "Storage Limitation",
    subItems: [
      "Retention Period: Store personal data in a form that permits identification of individuals for no longer than necessary for the purposes for which the data was collected.",
      "Data Deletion: Implement procedures for timely deletion or anonymization of data that is no longer needed.",
    ],
  },
  {
    title: "Integrity and Confidentiality",
    subItems: [
      "Security Measures: Implement appropriate technical and organizational measures to protect personal data from unauthorized access, disclosure, alteration, or destruction.",
      "Confidentiality: Ensure that all personnel who process personal data are bound by confidentiality agreements.",
    ],
  },
  {
    title: "Accountability",
    subItems: [
      "Data Protection Officer (DPO): Appoint a DPO, if required, to oversee GDPR compliance within the organization.",
      "Documentation: Maintain records of data processing activities, including the purposes of processing, data categories, and recipients.",
      "Impact Assessments: Conduct Data Protection Impact Assessments (DPIAs) for high-risk processing activities.",
    ],
  },
  {
    title: "Individual Rights",
    subItems: [
      "Right to Access: Individuals have the right to access their personal data and obtain information about its processing.",
      "Right to Rectification: Individuals can request correction of inaccurate or incomplete data.",
      "Right to Erasure (Right to be Forgotten): Individuals can request the deletion of their data under certain circumstances.",
      "Right to Restrict Processing: Individuals can request the restriction of processing under specific conditions.",
      "Right to Data Portability: Individuals can request a copy of their data in a structured, commonly used, and machine-readable format.",
      "Right to Object: Individuals can object to the processing of their data for certain purposes, such as direct marketing.",
      "Rights Related to Automated Decision-Making: Individuals have rights concerning automated decision-making and profiling, including the right to human intervention.",
    ],
  },
  {
    title: "Data Breach Notification",
    subItems: [
      "Notification Obligation: Notify the relevant supervisory authority of a personal data breach within 72 hours of becoming aware of it, unless the breach is unlikely to result in a risk to individuals' rights and freedoms.",
      "Communication to Individuals: If the breach poses a high risk, inform the affected individuals without undue delay.",
    ],
  },
  {
    title: "International Data Transfers",
    subItems: [
      "Adequate Safeguards: Ensure that data transferred outside the European Economic Area (EEA) is protected by adequate safeguards, such as standard contractual clauses or binding corporate rules.",
      "Third-Country Transfers: Only transfer data to countries outside the EEA if they provide an adequate level of data protection.",
    ],
  },
  {
    title: "Data Processing Agreements",
    subItems: [
      "Processor Contracts: Ensure that data processing agreements are in place with third-party processors, outlining their GDPR obligations and responsibilities.",
      "Sub-processors: Obtain consent from the data controller before engaging sub-processors, and ensure they adhere to GDPR requirements.",
    ],
  },
  {
    title: "Children's Data",
    subItems: [
      "Parental Consent: Obtain parental consent before processing the personal data of children under the age of 16 (or as defined by national law).",
      "Clear Language: Ensure that privacy notices for children are written in clear and age-appropriate language.",
    ],
  },
  {
    title: "Record-Keeping and Audits",
    subItems: [
      "Record-Keeping: Maintain detailed records of all data processing activities to demonstrate compliance with GDPR.",
      "Audits and Reviews: Regularly audit data protection practices and policies to ensure ongoing compliance with GDPR.",
    ],
  },
  {
    title: "Data Protection by Design and by Default",
    subItems: [
      "Design Principles: Implement data protection measures from the outset of any new project or process involving personal data.",
      "Default Settings: Ensure that privacy settings are set to the most protective level by default.",
    ],
  },
  {
    title: "Training and Awareness",
    subItems: [
      "Staff Training: Provide regular GDPR training to all employees who handle personal data.",
      "Awareness Campaigns: Promote awareness of data protection principles and the importance of GDPR compliance within the organization.",
    ],
  },
];

const dummy = [
  {
    id: 0,
    image: inPersonIcon,
    name: "Erick K.",
    gender: "Male",
    age: "37",
    date: "01/09/2034",
  },
  {
    id: 1,
    image: inPersonIcon,
    name: "Erick K.",
    gender: "Male",
    age: "37",
    date: "01/09/2034",
  },
  {
    id: 2,
    image: inPersonIcon,
    name: "Erick K.",
    gender: "Male",
    age: "37",
    date: "01/09/2034",
  },
  {
    id: 3,
    image: inPersonIcon,
    name: "Erick K.",
    gender: "Male",
    age: "37",
    date: "01/09/2034",
  },
];
