import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { membershipEndpoints } from "../apis";
import { getBucketURL } from "../../utils/regex";
import {
  setLoading,
  setIsSubscribed,
  setCurrentPlan,
  setPerks,
  setSubscriptionPlans,
} from "../../slices/membershipSlice";

const {
  GET_ALL_MEMBERSHIP_PLANS,
  GET_ALL_PERKS,
  GET_CURRENT_MEMBERSHIP,
  INITIATE_PAYMENT,
  CREATE_SUBSCRIPTION,
  UPGRADE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
} = membershipEndpoints;

// GET ALL MEMBERSHIP PLANS
export function getAllMembershipPlans(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_ALL_MEMBERSHIP_PLANS,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log(
        "GET_ALL_MEMBERSHIP_PLANS API RESPONSE............",
        response?.data?.data
      );
      dispatch(setSubscriptionPlans(response?.data?.data || []));
    } catch (error) {
      console.log("GET_ALL_MEMBERSHIP_PLANS API ERROR............", error);
    }
    dispatch(setLoading(false));
  };
}

// GET ALL PERKS
export function getAllPerks(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_PERKS, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log("GET_ALL_PERKS API RESPONSE............", response);
      dispatch(setPerks(response?.data?.perks || []));
    } catch (error) {
      console.log("GET_ALL_PERKS API ERROR............", error);
    }
    dispatch(setLoading(false));
  };
}

// GET CURRENT MEMBERSHIP
export function getCurrentMembership(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_CURRENT_MEMBERSHIP, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log("GET_CURRENT_MEMBERSHIP API RESPONSE............", response);
      if (response?.data?.data?.subscription) {
        dispatch(setIsSubscribed(true));
        dispatch(setCurrentPlan(response?.data?.data));
      } else {
        dispatch(setIsSubscribed(false));
        dispatch(setCurrentPlan(null));
      }
    } catch (error) {
      console.log("GET_CURRENT_MEMBERSHIP API ERROR............", error);
    }
    dispatch(setLoading(false));
  };
}

// INITIATE PAYMENT
export async function initiatePayment(token, body) {
  let result = [];
  try {
    const response = await apiConnector("POST", INITIATE_PAYMENT, body, {
      Authorization: `Bearer ${token}`,
    });

    if (!response?.status === 200) {
      throw new Error(response?.data?.message);
    }

    console.log("INITIATE_PAYMENT API RESPONSE............", response);

    result = response?.data;
  } catch (error) {
    console.log("INITIATE_PAYMENT API ERROR............", error);
  }
  return result;
}

// CREATE SUBSCRIPTION
export function createSubscription(token, body) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", CREATE_SUBSCRIPTION, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log("CREATE_SUBSCRIPTION API RESPONSE............", response);
    } catch (error) {
      console.log("CREATE_SUBSCRIPTION API ERROR............", error);
    }
    dispatch(setLoading(false));
  };
}

// UPDATE SUBSCRIPTION
export function updateSubscription(token, body) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", UPDATE_SUBSCRIPTION, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log("UPDATE_SUBSCRIPTION API RESPONSE............", response);
    } catch (error) {
      console.log("UPDATE_SUBSCRIPTION API ERROR............", error);
    }
    dispatch(setLoading(false));
  };
}

// UPGRADE SUBSCRIPTION
export async function upgradeSubscription(token, body) {
  let result = [];
  try {
    const response = await apiConnector("POST", UPGRADE_SUBSCRIPTION, body, {
      Authorization: `Bearer ${token}`,
    });

    if (!response?.status === 200) {
      throw new Error(response?.data?.message);
    }

    console.log("UPGRADE_SUBSCRIPTION API RESPONSE............", response);

    result = response?.data;
  } catch (error) {
    console.log("UPGRADE_SUBSCRIPTION API ERROR............", error);
  }
  return result;
}
