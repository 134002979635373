import React, { useEffect, useState } from "react";
import { RxDotsHorizontal } from "react-icons/rx";
import { FaPlus } from "react-icons/fa6";
import expandIcons from "../assets/Images/TelehealthDashboard/expandIcons.png";
import { BiExpandAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getKeyPerformanceIndicator,
  getCustomKeyPerformanceIndicator,
} from "../services/operations/masterDataAPI";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import moment from "moment";
import expandIcons1 from "../assets/Images/TelehealthDashboard/expandIcons1.png";
import { useTranslation } from 'react-i18next';

const Payouts = ({ onHideTile3 }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [payoutType, setPayoutType] = useState("Week");
  const [todayData, setTodayData] = useState([]);
  const [weekData, setWeekData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [customData, setCustomData] = useState([]);
  const [showHideTile, setShowHideTile] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      color: "#3CA5DC",
    },
  ]);

  useEffect(() => {
    const fetchKeyPerformanceIndicator = async () => {
      if (token) {
        try {
          let body;
          let res;
          if (payoutType === "dateRange") {
            const startDate = moment(dateRange[0].startDate).format(
              "YYYY-MM-DD"
            );
            const endDate = moment(dateRange[0].endDate).format("YYYY-MM-DD");
            body = {
              startDate: startDate,
              endDate: endDate,
            };
            res = await dispatch(getCustomKeyPerformanceIndicator(token, body));
            setCustomData(res?.KPI?.CustomDateRange);
          } else {
            res = await dispatch(getKeyPerformanceIndicator(token));
            setTodayData(res?.KPI?.Today);
            setWeekData(res?.KPI?.Week);
            setMonthData(res?.KPI?.Month);
          }
        } catch (error) {
          console.error(t('fetchKPIError'), error);
        }
      }
    };

    fetchKeyPerformanceIndicator();
  }, [token, dispatch, payoutType, dateRange]);

  return (
    <div
      className={`flex-1 basis-[40%] h-[47vh] p-4 rounded-lg shadow bg-[#FFFFFF] relative`}
    >
      <div className="flex flex-col justify-center item-center">
        {/* Header */}
        <div className="flex flex-row justify-between items-center relative">
          {showHideTile ? (
            <div
              onClick={() => {
                setShowHideTile(false);
                onHideTile3("showPayouts");
              }}
              className={`cursor-pointer flex justify-center items-center py-1 px-2 bg-[#E5F2FF] rounded-3xl`}
            >
              <h1
                className={`text-sky-400 text-[11.03px] font-semibold font-poppins`}
              >
                {t('hideTile')}
              </h1>
            </div>
          ) : (
            <div
              onClick={() => setShowHideTile(true)}
              className="cursor-pointer flex justify-center items-center bg-[#E5F2FF] rounded-full p-1 w-[30px] h-[30px]"
            >
              <RxDotsHorizontal className="text-sky-400 text-lg" />
            </div>
          )}
          <div className="text-zinc-900 text-lg font-semibold font-poppins">
            {t('clinicPerformance')}
          </div>

          {/* Date Range btn */}
          <button
            onClick={() => {
              setShowCalendar(!showCalendar);
            }}
            className="flex flex-row justify-center items-center"
          >
            <div className="text-sky-400 text-sm font-semibold font-poppins leading-none">
              {t('selectDateRange')}
            </div>
          </button>
          {showCalendar && (
            <div className="flex flex-col justify-center items-center gap-1 absolute top-[1.5rem] right-1 w-[21rem] shadow-lg rounded-md">
              <DateRange
                date={new Date()}
                shownDate={new Date()}
                onChange={(item) => {
                  setDateRange([item.selection]);
                  setPayoutType("dateRange");
                  setShowCalendar(false);
                }}
                ranges={dateRange}
                months={1}
                showDateDisplay={false}
                rangeColors={["#3CA5DC"]}
                direction="horizontal"
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                className=""
              />
            </div>
          )}
        </div>

        {/* Content */}
        <div className="flex flex-col w-full justify-center items-center my-4">
          {/* 3 Btns */}
          <div
            className={`flex flex-row justify-start items-center w-full gap-3 mb-4`}
          >
            <div
              onClick={() => setPayoutType("Today")}
              className={`cursor-pointer hover:scale-110 duration-500 flex justify-center items-center py-2 px-3 ${
                payoutType === "Today" ? "bg-sky-100" : "bg-gray-100"
              } rounded-3xl`}
            >
              <h1
                className={`${
                  payoutType === "Today"
                    ? "text-neutral-700"
                    : "text-neutral-600"
                } text-xs font-semibold font-poppins leading-none`}
              >
                {t('today')}
              </h1>
            </div>
            <div
              onClick={() => setPayoutType("Week")}
              className={`cursor-pointer hover:scale-110 duration-500 flex justify-center items-center py-2 px-3 ${
                payoutType === "Week" ? "bg-sky-100" : "bg-gray-100"
              } rounded-3xl`}
            >
              <h1
                className={`${
                  payoutType === "Week"
                    ? "text-neutral-700"
                    : "text-neutral-600"
                } text-xs font-semibold font-poppins leading-none`}
              >
                {t('week')}
              </h1>
            </div>
            <div
              onClick={() => setPayoutType("Month")}
              className={`cursor-pointer hover:scale-110 duration-500 flex justify-center items-center py-2 px-3 ${
                payoutType === "Month" ? "bg-sky-100" : "bg-gray-100"
              } rounded-3xl`}
            >
              <h1
                className={`${
                  payoutType === "Month"
                    ? "text-neutral-700"
                    : "text-neutral-600"
                } text-xs font-semibold font-poppins leading-none`}
              >
                {t('month')}
              </h1>
            </div>
          </div>

          {/* KPI */}
          <div className="flex flex-col gap-4 mb-2">
            <div className={`flex flex-row gap-4 w-full`}>
              <div
                className="flex-1 flex justify-start items-center py-4 px-4 rounded-2xl shadow"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.55)" }}
              >
                <div className="flex flex-col justify-center items-start gap-2">
                  <h1 className="text-[#000000] text-[14.83px] font-medium font-poppins text-left capitalize tracking-tight">
                    {t('patientsSeen')}
                  </h1>
                  <h1 className="text-sky-400 text-base font-medium font-poppins">
                    {payoutType === "Today"
                      ? todayData?.patientsSeen
                      : payoutType === "Week"
                      ? weekData?.patientsSeen
                      : payoutType === "Month"
                      ? monthData?.patientsSeen
                      : payoutType === "dateRange"
                      ? customData?.patientsSeen
                      : ""}
                  </h1>
                </div>
              </div>
              <div
                className="flex-1 flex justify-start items-center py-4 px-4 rounded-2xl shadow"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.55)" }}
              >
                <div className="flex flex-col justify-center items-start gap-2">
                  <h1 className="text-[#000000] text-[14.83px] font-medium font-poppins text-left">
                    {t('payoutAmount')}
                  </h1>
                  <h1 className="text-sky-400 text-base font-medium font-poppins">
                    {payoutType === "Today"
                      ? todayData?.RevenueAmount
                      : payoutType === "Week"
                      ? weekData?.RevenueAmount
                      : payoutType === "Month"
                      ? monthData?.RevenueAmount
                      : payoutType === "dateRange"
                      ? customData?.RevenueAmount
                      : ""}{" "}
                    {t('currency')}
                  </h1>
                </div>
              </div>
            </div>
            <div className={`flex flex-row gap-4 w-full`}>
              <div
                className="flex-1 flex justify-start items-center py-4 px-4 rounded-2xl shadow"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.55)" }}
              >
                <div className="flex flex-col justify-center items-start gap-2">
                  <h1 className="text-[#000000] text-[14.83px] font-medium font-poppins text-left">
                    {t('clinicOccupancyRate')}
                  </h1>
                  <h1 className="text-sky-400 text-base font-medium font-poppins">
                    {payoutType === "Today"
                      ? todayData?.occupancyRate
                      : payoutType === "Week"
                      ? weekData?.occupancyRate
                      : payoutType === "Month"
                      ? monthData?.occupancyRate
                      : payoutType === "dateRange"
                      ? customData?.occupancyRate
                      : ""}
                    %
                  </h1>
                </div>
              </div>
              <div
                className="flex-1 flex justify-start items-center py-4 px-4 rounded-2xl shadow"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.55)" }}
              >
                <div className="flex flex-col justify-center items-start gap-2">
                  <h1 className="text-[#000000] text-base font-medium font-poppins text-left">
                    {t('revenuePerPatient')}
                  </h1>
                  <h1 className="text-sky-400 text-base font-medium font-poppins text-left">
                    {payoutType === "Today"
                      ? todayData?.RevenuePerPatient
                      : payoutType === "Week"
                      ? weekData?.RevenuePerPatient
                      : payoutType === "Month"
                      ? monthData?.RevenuePerPatient
                      : payoutType === "dateRange"
                      ? customData?.RevenuePerPatient
                      : ""}{" "}
                    {t('currencyPerPatient')}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          {/* Full screen Icon */}
          {/* <div className="flex flex-row justify-end items-center w-full absolute bottom-2 right-4">
            <button
              className="text-sky-400 text-2xl active:scale-90 transition-transform border rounded-lg border-[#D1D1D1] w-7 h-7 p-1"
              onClick={() => {
                navigate("/clinicianDashboard/payouts");
              }}
            >
              <img
                src={expandIcons1}
                className="w-full h-full object-contain"
                alt={t('expandIcons')}
              />
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Payouts;