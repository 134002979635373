import React, { useEffect, useState } from "react";
import { DayPicker, getDefaultClassNames } from "react-day-picker";
import "react-day-picker/dist/style.css";
import ModalComponent from "../components/Modal";
import moment from "moment";
import cross_icon from "../assets/Images/MasterDataManagement/cross_icon.svg";
import add_icon from "../assets/Images/MasterDataManagement/add_icon.svg";
import { useNavigate } from "react-router-dom";
import { TimePicker } from "antd";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  getOverrideAvailability,
  overrideAvailability,
  removeOverrideAvailability,
} from "../services/operations/masterDataAPI";

function OverrideAvailability({ doc }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const { overrideData, loading: masterDataLoading } = useSelector(
    (state) => state.masterData
  );
  const defaultClassNames = getDefaultClassNames();
  const [showOverrideModal, setShowOverrideModal] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [availability, setAvailability] = useState({});
  const [timeErrors, setTimeErrors] = useState({});

  const handleSelect = (dates) => {
    const newAvailability = { ...availability };

    // Find dates that are no longer selected
    const deselectedDates = Object.keys(newAvailability).filter(
      (date) =>
        !dates.some(
          (selectedDate) => moment(selectedDate).format("YYYY-MM-DD") === date
        )
    );

    // Remove deselected dates from availability
    deselectedDates.forEach((date) => {
      delete newAvailability[date];
    });

    // Add new selected dates to availability
    dates.forEach((date) => {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      if (!newAvailability[formattedDate]) {
        newAvailability[formattedDate] = [];
      }
    });

    setSelectedDates(dates);
    setAvailability(newAvailability);
  };

  const addInterval = (date) => {
    setAvailability((prev) => ({
      ...prev,
      [date]: [...(prev[date] || []), { from: null, to: null }],
    }));
  };
  const updateInterval = (date, index, type, time) => {
    setAvailability((prev) => {
      const updatedIntervals = [...prev[date]];
      updatedIntervals[index][type] = time
        ? moment(time, "HH:mm").format("HH:mm")
        : null;

      let isValid = true;
      if (type === "from") {
        isValid = validateTimeInterval(time, updatedIntervals[index].to);
      } else {
        isValid = validateTimeInterval(updatedIntervals[index].from, time);
      }

      const newTimeErrors = { ...timeErrors };
      if (!isValid) {
        newTimeErrors[`${date}-${index}`] = true;
        toast.error("Invalid time interval");
      } else {
        delete newTimeErrors[`${date}-${index}`];
      }
      setTimeErrors(newTimeErrors);

      return { ...prev, [date]: updatedIntervals };
    });
  };

  const validateTimeInterval = (fromTime, toTime) => {
    if (fromTime && toTime) {
      const from = moment(fromTime, "HH:mm");
      const to = moment(toTime, "HH:mm");
      return to.isAfter(from);
    }
    return true;
  };

  const removeInterval = (date, index) => {
    setAvailability((prev) => {
      const updatedIntervals = [...prev[date]];
      updatedIntervals.splice(index, 1);
      return { ...prev, [date]: updatedIntervals };
    });
  };

  const validateTimeIntervals = () => {
    for (const date in availability) {
      if (availability[date].length === 0) {
        continue; // Skip dates with empty arrays
      }
      for (const interval of availability[date]) {
        if (!interval.from || !interval.to) {
          return `${date} has an incomplete time interval.`;
        }
        if (!validateTimeInterval(interval.from, interval.to)) {
          return `${date} has an invalid time interval.`;
        }
      }
    }

    if (Object.values(timeErrors).some((error) => error)) {
      return "There are time-related errors. Please correct them before submitting.";
    }

    return null; // No errors found
  };

  const submitHandler = async () => {
    // Validate time intervals
    const timeValidationError = validateTimeIntervals();
    if (timeValidationError) {
      toast.error(timeValidationError);
      return;
    }

    const formattedAvailability = Object.keys(availability).reduce(
      (acc, date) => {
        if (availability[date].length > 0) {
          const intervals = availability[date].map((interval) => {
            const fromTime = moment(interval.from, "HH:mm").isValid()
              ? moment(interval.from, "HH:mm").format("HH:mm:ss")
              : null;
            const untilTime = moment(interval.to, "HH:mm").isValid()
              ? moment(interval.to, "HH:mm").format("HH:mm:ss")
              : null;

            return {
              Available_From: fromTime,
              Available_Until: untilTime,
            };
          });

          acc.push({
            LocalDate: date,
            Availability: intervals,
          });
        }
        return acc;
      },
      []
    );

    const payload = {
      TimeZone: "Asia/Calcutta",
      DatesWithAvailability: formattedAvailability,
    };

    dispatch(overrideAvailability(token, doc?.DID, payload));
    handleClose();
  };

  const handleOpenModal = () => {
    const dates = overrideData?.map((item) => moment(item.LocalDate).toDate());
    const newAvailability = overrideData?.reduce((acc, item) => {
      acc[item.LocalDate] = item.Availability.map((interval) => ({
        from: moment(interval.Available_From, "HH:mm:ss").format("HH:mm"),
        to: moment(interval.Available_Until, "HH:mm:ss").format("HH:mm"),
      }));
      return acc;
    }, {});

    setSelectedDates(dates);
    setAvailability(newAvailability);
    setShowOverrideModal(true);
  };

  const handleClose = () => {
    setShowOverrideModal(false);
    setSelectedDates([]);
    setAvailability({});
  };

  useEffect(() => {
    dispatch(getOverrideAvailability(token, doc?.DID));
  }, []);

  console.log("====================================");
  console.log("Override Data:", overrideData);
  console.log("====================================");

  const handleRemoveOverride = (date) => {
    const body = {
      TimeZone: "Asia/Calcutta",
      LocalDate: [date],
    };
    dispatch(removeOverrideAvailability(token, doc?.DID, body));
  };

  if (masterDataLoading) {
    return (
      <div className="flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div>
      <ModalComponent
        show={showOverrideModal}
        handleClose={() => {
          setShowOverrideModal(false);
          handleClose();
        }}
        outSideModalPress={false}
        showCloseButton={true}
      >
        <div>
          <div className="text-zinc-900 text-lg font-semibold font-poppins leading-relaxed">
            Override Availability
          </div>
          <div className="text-neutral-600 text-lg font-normal font-poppins leading-relaxed">
            Select the dates
          </div>
          <div className="flex gap-8 mt-4">
            <div className="shadow-xl rounded-md overflow-hidden">
              <DayPicker
                mode="multiple"
                selected={selectedDates}
                onSelect={handleSelect}
                disabled={{ before: new Date() }}
                classNames={{
                  today: `text-sky-500`,
                  selected: `bg-sky-500 border-amber-500 text-white rounded-full`,
                  root: `${defaultClassNames.root} shadow-lg p-5`,
                  navButtonPrev: `${defaultClassNames.navButtonPrev} text-sky-500`,
                }}
              />
            </div>
            {Object.keys(availability).length > 0 && (
              <div className="w-[25vw] flex flex-col gap-4 max-h-[35vh] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                {Object.keys(availability).map((date) => (
                  <div key={date} className="flex flex-col gap-2">
                    <div className="text-zinc-900 text-xs font-medium font-poppins">
                      {date}
                    </div>
                    {availability[date].length > 0 ? (
                      availability[date].map((interval, index) => (
                        <div key={index} className="flex items-center gap-4">
                          <p className="text-black text-xs font-normal font-poppins leading-none">
                            From
                          </p>
                          <TimePicker
                            format="HH:mm"
                            value={
                              interval.from
                                ? moment(interval.from, "HH:mm")
                                : null
                            }
                            onChange={(time, timeString) =>
                              updateInterval(date, index, "from", timeString)
                            }
                            onOk={(time) =>
                              updateInterval(
                                date,
                                index,
                                "from",
                                time.format("HH:mm")
                              )
                            }
                            size="large"
                            minuteStep={30}
                            changeOnScroll={false}
                            status={
                              timeErrors[`${date}-${index}`] ? "error" : ""
                            }
                          />
                          <p className="text-black text-xs font-normal font-poppins leading-none">
                            To
                          </p>
                          <TimePicker
                            format="HH:mm"
                            value={
                              interval.to ? moment(interval.to, "HH:mm") : null
                            }
                            onChange={(time, timeString) =>
                              updateInterval(date, index, "to", timeString)
                            }
                            onOk={(time) =>
                              updateInterval(
                                date,
                                index,
                                "to",
                                time.format("HH:mm")
                              )
                            }
                            size="large"
                            minuteStep={30}
                            changeOnScroll={false}
                            status={
                              timeErrors[`${date}-${index}`] ? "error" : ""
                            }
                          />
                          <button onClick={() => removeInterval(date, index)}>
                            <img src={cross_icon} alt="remove" />
                          </button>
                          <button onClick={() => addInterval(date)}>
                            <img src={add_icon} alt="add" />
                          </button>
                        </div>
                      ))
                    ) : (
                      <div className="flex flex-row items-center space-x-4">
                        <div className="w-80 p-4 bg-neutral-50 rounded-lg text-neutral-600 text-sm font-normal font-poppins">
                          Not available
                        </div>
                        <button onClick={() => addInterval(date)}>
                          <img src={add_icon} alt="add" />
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>

          {Object.keys(availability).length > 0 && (
            <div className="w-1/2 mx-auto flex flex-row items-center justify-center mt-6 space-x-4">
              <button
                onClick={handleClose}
                className="flex-1 px-4 py-2 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
              >
                <div className="text-sky-500 text-sm font-semibold font-poppins leading-normal">
                  Cancel
                </div>
              </button>
              <button
                onClick={submitHandler}
                className="flex-1 px-4 py-2 flex items-center justify-center bg-sky-500 rounded-lg border border-sky-500 active:scale-95"
              >
                <div className="text-white text-sm font-semibold font-poppins leading-normal">
                  Apply
                </div>
              </button>
            </div>
          )}
        </div>
      </ModalComponent>
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="text-zinc-900 text-lg font-semibold font-poppins leading-relaxed">
          Override Availability
        </div>
        <div className="text-neutral-600 text-lg font-normal font-poppins leading-relaxed mt-1">
          Adjust your availability for specific days when your hours differ from
          your regular schedule or when you're unavailable.
        </div>

        <div className="mt-6">
          {overrideData.length > 0
            ? overrideData.map((item) => (
                <div
                  key={item.LocalDate}
                  className="bg-white rounded-lg shadow-md p-4 mb-4 hover:bg-sky-100 transition-all duration-200"
                >
                  <div className="flex flex-row items-center justify-between">
                    <div className="text-zinc-900 text-sm font-normal font-poppins">
                      {moment(item.LocalDate).format("DD MMM YYYY")}
                    </div>
                    <div className="flex flex-col">
                      {item.Availability.length > 0 ? (
                        item.Availability.map((interval, index) => (
                          <div key={index}>
                            <div className="text-zinc-900 text-sm font-normal font-poppins">
                              {moment(
                                interval.Available_From,
                                "HH:mm:ss"
                              ).format("hh:mm A")}{" "}
                              to{" "}
                              {moment(
                                interval.Available_Until,
                                "HH:mm:ss"
                              ).format("hh:mm A")}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="text-zinc-900 text-sm font-normal font-poppins">
                          Not Available
                        </div>
                      )}
                    </div>
                    <button
                      onClick={() => handleRemoveOverride(item.LocalDate)}
                    >
                      <img src={cross_icon} alt="remove" />
                    </button>
                  </div>
                </div>
              ))
            : null}
        </div>
        <button
          onClick={handleOpenModal}
          className="px-4 py-2 bg-white rounded-lg border border-sky-500 justify-center items-center gap-2 inline-flex mt-4 active:scale-95"
        >
          <img src={add_icon} alt="Add" />
          <div className="text-sky-500 text-sm font-medium font-poppins leading-tight">
            Add date-specific hours
          </div>
        </button>
      </div>
    </div>
  );
}

export default OverrideAvailability;
