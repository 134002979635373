import React from "react";
import { useTranslation } from "react-i18next";
import TitleWithAddButton from "./TitleWithAddButton";
import noFamilyMedicalHistory from "../assets/Images/PatientProfile/noFamilyMedicalHistory.png";

function FamilyHistory({ hasEditAccess }) {
  const { t } = useTranslation();

  return (
    <div>
      <TitleWithAddButton
        title={t("familyHistory")}
        showAddButton={hasEditAccess}
      />
      {/* <div className="w-full bg-neutral-50 rounded-lg p-4  mt-4">
        <div className="flex items-center">
          <div className="flex-1">
            <p className="text-zinc-500 text-xs font-normal font-poppins">
              {t("condition")}
            </p>
            <p className="text-black text-base font-semibold font-poppins mt-1">
              {t("heartDisease")}
            </p>
          </div>
          <div className="flex-1">
            <p className="text-zinc-500 text-xs font-normal font-poppins">
              {t("relation")}
            </p>
            <p className="text-black text-base font-semibold font-poppins mt-1">
              {t("father")}
            </p>
          </div>
        </div>
        <div className="text-black text-sm font-normal font-poppins mt-3">
          {t("notes", { note: "Father had heart attack at 45" })}
        </div>
      </div> */}
      <div className="my-4">
        <img
          src={noFamilyMedicalHistory}
          alt={t("noData")}
          className="w-44 h-44 mx-auto"
        />
        <div className="text-center text-neutral-600 text-sm font-normal font-poppins leading-normal mt-4">
          {t("noMedicalHistoryDataYet")}
        </div>
      </div>
    </div>
  );
}

export default FamilyHistory;